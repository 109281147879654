import React from 'react';
import cls from '../../utils/cls.util';
import styles from './AlertTag.module.css';

type AlertTagProps = {
  value?: string | number;
};

type Urgency = 'low' | 'medium' | 'high';

function AlertTag(props: AlertTagProps): JSX.Element {
  const { value = 0 } = props;
  let urgency: Urgency = 'low';

  if (Number(value) >= 3) {
    urgency = 'high';
  } else if (Number(value) > 1) {
    urgency = 'medium';
  }

  const iconStates: Record<Urgency, string> = {
    low: styles.low,
    medium: styles.medium,
    high: styles.high,
  };

  return (
    <p className={styles.tag}>
      <span className={cls(urgency, styles.icon, iconStates)}></span>
      {value}
    </p>
  );
}

export default AlertTag;
