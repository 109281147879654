import { AuthSliceType } from '../auth.slice';
import { User } from '../../../../types/definitions';
import { PayloadAction } from '@reduxjs/toolkit';

export default function updateAuthUser(
  state: AuthSliceType,
  action: PayloadAction<Partial<User>>
): void {
  if (state.value.user) {
    state.value.user = {
      ...state.value.user,
      ...action.payload,
    };
  } else {
    state.value.user = {
      ...action.payload,
    } as User;
  }
}
