import React from 'react';
import Accordion from '../../components/Accordion/Accordion.component';
import styles from './Counter.module.css';
import { Filter } from '../../../../components/Filter';
import { useEffect, useState } from 'react';
import { CooperativeCounter } from './types/Counter.types';
import OperationMS from '../../../../services/ms/OperationMS.service';
import useFilters from '../../../../hooks/useFilters.hook';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon.component';
import downloadBlob from '../../../../@utils/blob/downloadBlob';
import * as XLSX from 'xlsx';
import CooperativesMS from '../../../../services/ms/CooperativesMS.service';
import Loading from '@spot-spotsat/components/Loading/Loading';

const periodEnum = {
  month: 'Mês',
  year: 'Ano',
};

function Counter() {
  const [searchParams] = useSearchParams();

  const [renderKey, setRenderKey] = useState(0);

  const { filters, addFilter, clearFilters, applyFilters } = useFilters();

  const [cooperativesCounter, setCooperativesCounter] = useState<
    CooperativeCounter[]
  >([]);

  const [counterLoading, setCounterLoading] = useState(false);

  const [stateFilter, setStateFilter] = useState<{
    filterType: 'month' | 'year' | '';
    month: string;
    year: string;
  }>({
    filterType: '',
    month: '',
    year: '',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const toggleLoading = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const fetchCooperatives = async () => {
    try {
      setRenderKey((prev) => prev + 1);
      const response = await CooperativesMS.All();
      if (response) {
        response.results.map((cooperative) => {
          setCooperativesCounter((prev) => [
            ...prev,
            {
              cooperative: cooperative,
              data: null,
            },
          ]);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (filters.hasOwnProperty('tab')) {
      addFilter('tab', '');
    }

    fetchCooperatives();
    clearFilters();
  }, []);

  useEffect(() => {
    if (stateFilter.filterType || stateFilter.filterType === '') {
      addFilter('year', stateFilter.year);
      addFilter('month', stateFilter.month);
    }
  }, [stateFilter.filterType, addFilter]);

  const [error, setError] = useState<boolean>(false);

  const handleCounterCooperativeData = async (
    cooperativeId: number | string,
    filterSearchParams: URLSearchParams
  ) => {
    try {
      setCounterLoading(true);
      setError(false);
      if (!filterSearchParams) return;
      const response = await OperationMS.CounterByCooperative(
        cooperativeId,
        searchParams
      );

      if (response === false) {
        setError(true);
        return;
      }

      if (response) {
        setCooperativesCounter(
          cooperativesCounter.map((cooperativeCounter) => {
            if (
              cooperativeCounter.cooperative.external_reference ===
              cooperativeId
            ) {
              return {
                ...cooperativeCounter,
                data: response,
              };
            }
            return cooperativeCounter;
          })
        );
      }
      setError(false);
    } catch (error) {
      console.error(error);
    }
    setCounterLoading(false);
  };

  const getLabelDate = {
    current: {
      month: () => {
        if (Number(stateFilter.month) < 10) {
          return `0${stateFilter.month}/${stateFilter.year}`;
        }
        return `${stateFilter.month}/${stateFilter.year}`;
      },
      year: () => {
        return `${stateFilter.year}`;
      },
    },
    previous: {
      month: () => {
        const month =
          Number(stateFilter.month) === 1 ? 12 : Number(stateFilter.month) - 1;
        const year =
          Number(stateFilter.month) === 1
            ? Number(stateFilter.year) - 1
            : stateFilter.year;
        if (month < 10) {
          return `0${month}/${year}`;
        }
        return `${month}/${year}`;
      },
      year: () => {
        return `${Number(stateFilter.year) - 1}`;
      },
    },
    forecast: {
      month: () => {
        const month =
          Number(stateFilter.month) === 12 ? 1 : Number(stateFilter.month) + 1;
        const year =
          Number(stateFilter.month) === 12
            ? Number(stateFilter.year) + 1
            : stateFilter.year;
        if (month < 10) {
          return `0${month}/${year}`;
        }
        return `${month}/${year}`;
      },
      year: () => {
        return `${Number(stateFilter.year) + 1}`;
      },
    },
  };

  const getOperationsXLSX = async ({
    cooperativeId,
  }: {
    cooperativeId: string;
  }): Promise<string | boolean> => {
    try {
      const response: any = await OperationMS.ExportListOperationsCsv(
        cooperativeId,
        searchParams
      );

      if (!response) {
        throw new Error('Erro ao baixar o arquivo');
      }

      return await response.text();
    } catch (error) {
      console.error('Erro ao baixar o CSV:', error);
      return false;
    }
  };

  const getCounterCsv = async ({
    cooperativeId,
    period,
  }: {
    cooperativeId: string;
    period: 'current' | 'previous';
  }): Promise<string | boolean> => {
    const exportCsvSearchParams = new URLSearchParams(searchParams);
    if (period === 'previous') {
      let month = '';
      let year = '';
      if (stateFilter.filterType === 'month') {
        month =
          stateFilter.month === '1'
            ? '12'
            : (Number(stateFilter.month) - 1).toString();
        year =
          stateFilter.month === '1'
            ? (Number(stateFilter.year) - 1).toString()
            : stateFilter.year;
      }
      if (stateFilter.filterType === 'year') {
        month = '12';
        year = (Number(stateFilter.year) - 1).toString();
      }

      exportCsvSearchParams.set('month', month);
      exportCsvSearchParams.set('year', year);
    }

    try {
      const response: any = await OperationMS.ExportCounterByCooperativeCsv(
        cooperativeId,
        exportCsvSearchParams
      );

      if (!response) {
        throw new Error('Erro ao baixar o arquivo');
      }

      return response.text();
    } catch (error: any) {
      console.error('Erro ao baixar o CSV:', error);
      return false;
    }
  };

  const createXLSX = ({
    xlsx,
  }: {
    xlsx: {
      sheetName: string;
      sheetData: string;
    }[];
  }) => {
    const workbook = XLSX.utils.book_new();

    xlsx.forEach((sheet: { sheetName: string; sheetData: any }) => {
      const csvData = sheet.sheetData;

      const csvArray = csvData.split('\n').map(
        (row: string) =>
          row
            .split(',')
            .map((cell: string) => cell.trim().replace(/^"|"$/g, '')) // Remove aspas do início e fim de cada célula
      );

      const worksheet = XLSX.utils.aoa_to_sheet(csvArray);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
    });

    // Gera o arquivo XLSX
    const xlsxData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([xlsxData], { type: 'application/octet-stream' });
    return blob;
  };

  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (
      (stateFilter.filterType === 'year' && stateFilter.year !== '') ||
      (stateFilter.filterType === 'month' &&
        stateFilter.year !== '' &&
        stateFilter.month !== '')
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [stateFilter.filterType, stateFilter.year, stateFilter.month]);

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div className={styles.loadingForm}>
          <Loading />
        </div>
      )}
      <div className={styles.filters}>
        <Filter.Root>
          <Filter.Group>
            <Filter.Selector
              loading={false}
              label="Período"
              itemsType="object"
              items={[
                { label: 'Mês', value: 'month' },
                { label: 'Ano', value: 'year' },
              ]}
              itemLabelKey={'label'}
              itemValueKey={'value'}
              onClick={(e) => {
                const target = e.target as HTMLSelectElement;
                addFilter('filterType', target.value);
                setStateFilter({
                  ...stateFilter,
                  filterType: target.value as 'month' | 'year',
                  month: '',
                  year: '',
                });
              }}
              value={
                stateFilter['filterType']
                  ? stateFilter['filterType'] === 'month'
                    ? 'Mês'
                    : 'Ano'
                  : ''
              }
            />
            {stateFilter.filterType === 'month' && (
              <Filter.Selector
                label="Mês"
                itemsType="object"
                itemLabelKey={'month'}
                itemValueKey={'value'}
                disabled={stateFilter.filterType !== 'month'}
                items={[
                  { month: '01 - Janeiro', value: '1' },
                  { month: '02 - Fevereiro', value: '2' },
                  { month: '03 - Março', value: '3' },
                  { month: '04 - Abril', value: '4' },
                  { month: '05 - Maio', value: '5' },
                  { month: '06 - Junho', value: '6' },
                  { month: '07 - Julho', value: '7' },
                  { month: '08 - Agosto', value: '8' },
                  { month: '09 - Setembro', value: '9' },
                  { month: '10 - Outubro', value: '10' },
                  { month: '11 - Novembro', value: '11' },
                  { month: '12 - Dezembro', value: '12' },
                ]}
                onClick={(e) => {
                  const target = e.target as HTMLSelectElement;
                  addFilter('month', target.value);
                  setStateFilter({
                    ...stateFilter,
                    month: target.value,
                  });
                }}
              />
            )}
            <Filter.Selector
              label="Ano"
              itemsType="string"
              items={['2020', '2021', '2022', '2023', '2024']}
              value={stateFilter.year}
              onClick={(e) => {
                const target = e.target as HTMLSelectElement;
                addFilter('year', target.value);
                setStateFilter({
                  ...stateFilter,
                  year: target.value,
                });
                setRenderKey((prev) => prev + 1);
              }}
            />
          </Filter.Group>
          <Filter.Group type="flex">
            <Filter.Button
              label="Limpar filtros"
              variant="outline-secondary"
              iconName="Close"
              onClick={() => {
                clearFilters();
                setStateFilter({
                  filterType: '',
                  month: '',
                  year: '',
                });
              }}
            />
            <Filter.Button
              label="Filtrar"
              variant="filled-secondary"
              iconName="Filter"
              onClick={() => {
                applyFilters();
                fetchCooperatives();
                toggleLoading();
              }}
              disabled={disabledButton}
              className={`${
                disabledButton
                  ? styles.buttonFilterDisabled
                  : styles.buttonFilter
              }`}
            />
          </Filter.Group>
        </Filter.Root>
      </div>
      <div className={styles.collapse} key={renderKey}>
        {searchParams.size > 0 &&
          cooperativesCounter.map((cooperativeCounter, index) =>
            cooperativeCounter.cooperative ? (
              <Accordion
                key={index}
                title={cooperativeCounter.cooperative.name}
                onExpand={() =>
                  handleCounterCooperativeData(
                    cooperativeCounter.cooperative.external_reference,
                    searchParams
                  )
                }
              >
                {cooperativeCounter.data && !counterLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '16px',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <div className={styles.exportCsv}>
                      <div
                        className={styles.buttonCsv}
                        onClick={async () => {
                          const currentOperationsCsv = await getOperationsXLSX({
                            cooperativeId:
                              cooperativeCounter.cooperative.external_reference,
                          });

                          if (!currentOperationsCsv) {
                            return;
                          }

                          const file = createXLSX({
                            xlsx: [
                              {
                                sheetName:
                                  getLabelDate['current'][
                                    stateFilter.filterType as 'month' | 'year'
                                  ](),
                                sheetData: currentOperationsCsv as string,
                              },
                            ],
                          });

                          downloadBlob(
                            file as Blob,
                            `operations_cooperative_${
                              cooperativeCounter.cooperative.external_reference
                            }_${getLabelDate['current'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}.xlsx`
                          );
                        }}
                      >
                        <Icon name="XlsFile" /> Exportar Operações Monitoradas
                        em{' '}
                        {getLabelDate['current'][
                          stateFilter.filterType as 'month' | 'year'
                        ]()}
                      </div>
                      <div
                        className={styles.buttonCsv}
                        onClick={async () => {
                          const currentCounterCsv = await getCounterCsv({
                            cooperativeId:
                              cooperativeCounter.cooperative.external_reference,
                            period: 'current',
                          });

                          const previousCounterCsv = await getCounterCsv({
                            cooperativeId:
                              cooperativeCounter.cooperative.external_reference,
                            period: 'previous',
                          });

                          if (!currentCounterCsv || !previousCounterCsv) {
                            return;
                          }

                          const file = createXLSX({
                            xlsx: [
                              {
                                sheetName:
                                  getLabelDate['current'][
                                    stateFilter.filterType as 'month' | 'year'
                                  ](),
                                sheetData: currentCounterCsv as string,
                              },
                              {
                                sheetName:
                                  getLabelDate['previous'][
                                    stateFilter.filterType as 'month' | 'year'
                                  ](),
                                sheetData: previousCounterCsv as string,
                              },
                            ],
                          });

                          downloadBlob(
                            file as Blob,
                            `counter_cooperative_${
                              cooperativeCounter.cooperative.external_reference
                            }_${getLabelDate['current'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}.xlsx`
                          );
                        }}
                      >
                        <Icon name="XlsFile" /> Exportar Dados do Contador
                      </div>
                    </div>
                    <Accordion key={index} title="Operações Criadas" isChild>
                      <div className={styles.accordionContent}>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Atual - '}
                            {getLabelDate['current'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Quantidade de operações
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .current.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Informada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .current.reported_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .current.financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .current.planting_area
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Anterior - '}
                            {getLabelDate['previous'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Quantidade de operações
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .previously.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Informada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .previously.reported_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .previously.financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.created_operations
                                  .previously.planting_area
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                    <Accordion
                      key={index}
                      title="Operações Finalizadas"
                      isChild
                    >
                      <div className={styles.accordionContent}>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Atual - '}
                            {getLabelDate['current'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Quantidade de operações
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .current.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Informada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .current.reported_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .current.financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .current.planting_area
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Anterior - '}
                            {getLabelDate['previous'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Quantidade de operações
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .previously.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Informada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .previously.reported_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .previously.financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.finished_operations
                                  .previously.planting_area
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                    <Accordion
                      key={index}
                      title="Operações Monitoradas"
                      isChild
                    >
                      <div className={styles.accordionContent}>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Atual - '}
                            {getLabelDate['current'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.monitored_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.monitored_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>Área Total</p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.monitored_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.monitored_operations
                                  .total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.monitored_operations
                                  .total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.new_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.new_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.new_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Financiadas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.new_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.new_operations.total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.end_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.end_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.end_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.end_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .current.end_operations.total_planting_area
                              }
                            </p>
                          </div>
                        </div>
                        <div className={styles.titleContent}>
                          <p>
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' Anterior - '}
                            {getLabelDate['previous'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.monitored_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.monitored_operations
                                  .quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>Área Total</p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.monitored_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.monitored_operations
                                  .total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.monitored_operations
                                  .total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.new_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.new_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.new_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Financiadas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.new_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.new_operations.total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.end_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.end_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.end_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.end_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .previously.end_operations.total_planting_area
                              }
                            </p>
                          </div>
                        </div>

                        <div className={styles.titleContent}>
                          <p>
                            Previsão para o próximo{' '}
                            {
                              periodEnum[
                                stateFilter.filterType as 'month' | 'year'
                              ]
                            }
                            {' - '}
                            {getLabelDate['forecast'][
                              stateFilter.filterType as 'month' | 'year'
                            ]()}
                          </p>
                        </div>
                        <div className={styles.gridContainer}>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.monitored_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.monitored_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>Área Total</p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.monitored_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.monitored_operations
                                  .total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Total
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.monitored_operations
                                  .total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Operações Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.new_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Glebas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.new_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Monitoradas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.new_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas Financiadas
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.new_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Novas Áreas de Plantio
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.new_operations.total_planting_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Operações Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.end_operations.quantity
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Glebas Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.end_operations.quantity_glebes
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.end_operations.total_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área Financiada Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.end_operations.total_financed_area
                              }
                            </p>
                          </div>
                          <div>
                            <p className={styles.paragraphTitle}>
                              Área de Plantio Em Fim de Monitoramento
                            </p>
                            <p className={styles.paragraph}>
                              {
                                cooperativeCounter.data?.monitored_operations
                                  .forecast.end_operations.total_planting_area
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                ) : !error ? (
                  <p>Carregando...</p>
                ) : (
                  <p>Não foram encontrados dados nesse período.</p>
                )}
              </Accordion>
            ) : (
              <></>
            )
          )}
      </div>
    </div>
  );
}

export default Counter;
