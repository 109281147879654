import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './NewPassword.module.css';

import logo from '../../assets/logoSpotView.png';
import PasswordInput from '../../components/PasswordInput/PasswordInput.component';
import useWindowDimensions from '../../hooks/useWindowDimensions.hook';
import cls from '../../hooks/cls.util';
import Button from '../../components/Button/Button.component';

export default function NewPassword(): JSX.Element {
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const [passwords, setPasswords] = useState<string>('');
  const [isNoEqual, setIsNoEqual] = useState<boolean | undefined>(undefined);

  function verifyPasswords(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value !== passwords) {
      setIsNoEqual(true);
    } else {
      setIsNoEqual(false);
    }
  }

  function updatePassword() {}

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section className={styles.content}>
        <img className={styles.logo} alt="Logo" src={logo} />
        <form
          method="POST"
          onSubmit={(e) => e.preventDefault()}
          className={styles.form}
        >
          <div className={styles.heading}>
            <h1>Troque a sua senha</h1>
            <p>
              Digite uma nova senha no formulário abaixo, e em seguida,
              utiliza-a para autenticar-se.
            </p>
          </div>
          <fieldset>
            <PasswordInput
              name="Senha"
              onChange={(e) => setPasswords(e.target.value)}
            />
            <PasswordInput name="Confirmar senha" onChange={verifyPasswords} />
            {isNoEqual && (
              <p className={styles.error}>As senhas precisam ser iguais.</p>
            )}
          </fieldset>
          <div className={styles.actions}>
            <Button
              label="Atualizar senha"
              onClick={updatePassword}
              buttonProps={{ disabled: isNoEqual }}
            />
          </div>
        </form>
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
          {!isTabletAndPhones && <span className={styles.preview}></span>}
        </section>
      )}
    </main>
  );
}
