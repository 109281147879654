import { CooperativesMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class CooperativesMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_ACCOUNT_MS!}/api/cooperatives`;

  public static async All() {
    return await HTTP.GET<CooperativesMSTypes.All>(`${this.ms_base_url}`);
  }
}
