/**
 * Converts a string or number value to a formatted numeric string.
 *
 * @param {string | number} value - The value to be converted.
 * @param {boolean} [fraction=false] - Whether to include decimal places.
 * @return {string} The formatted numeric string.
 */
export default function toNumeric(
  value: string | number,
  fraction: boolean = false
): string {
  if (fraction) {
    return Number(value).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return Number(value).toLocaleString('pt-BR');
}
