import React from 'react';
import { Outlet } from 'react-router-dom';
import useStateSelector from '../store/hooks/useStateSelector.hook';

export default function Middleware(): JSX.Element {
  const { status, value } = useStateSelector('auth');

  const variant = 'spoteye';
  const currentHref = window.location.href;

  if (status === 'not-initialized' && (!value.token || !value.user)) {
    window.location.href = `${String(
      process.env.REACT_APP_URL_SPOTAUTH
    )}/login?redirectUrl=${currentHref}&variant=${variant}`;

    return <></>;
  }

  return <Outlet />;
}
