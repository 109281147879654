import React from 'react';
import styles from './ReprocessConfirmedModal.module.css';
import Button from '../../../../../components/Button/Button.component';

type ReprocessConfirmedModalProps = {
  forwardRef?: React.RefObject<HTMLDialogElement>;
};

export default function ReprocessConfirmedModal(
  props: ReprocessConfirmedModalProps
) {
  const { forwardRef }: ReprocessConfirmedModalProps = props;

  function CloseModal() {
    window.location.reload();
    forwardRef?.current?.close();
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconTitle}>
          <h4 className={styles.iconTitle}>Reprocessamento finalizado</h4>
        </div>
        <p className={styles.paragraph}>
          Recarregue a página para obter as informações atualizadas da operação.
        </p>
        <div className={styles.divButton}>
          <Button
            onClick={CloseModal}
            label="Recarregar página"
            className={styles.buttonSend}
          />
        </div>
      </div>
    </dialog>
  );
}
