type ObjectKey = string | number | symbol;

/**
 * Groups an array of objects by a specified key.
 *
 * @template K - The type of the key property in the objects.
 * @template T - The type of the objects in the array.
 * @param {T[]} arr - The array of objects to be grouped.
 * @param {K} key - The key property to group the objects by.
 * @return {Record<ObjectKey, T[]>} - An object with the grouped objects, where the keys are the unique values of the specified key property.
 */
export default function groupBy<
  K extends ObjectKey,
  T extends Record<K, ObjectKey>
>(arr: T[], key: K): Record<ObjectKey, T[]> {
  return arr.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue[key]]: [
        ...(accumulator[currentValue[key]] || []),
        currentValue,
      ],
    }),
    {} as Record<ObjectKey, T[]>
  );
}
