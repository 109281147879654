type CSSStyleClass = string;

/**
 * @overload Função que recebe mescla estilos CSS com múltiplas condicionais, similar a um switch-case.
 *
 * @param {string} condition Condição para a mesclagem dos estilos.
 * @param {CSSStyleClass} defaultStyle Estilo padrão que será aplicado independente da condição.
 * @param {Record<string, CSSStyleClass>} styleIfTrue Estulo que será mesclado caso a condição corresponder à chave do objeto.
 * @returns {CSSStyleClass} Objeto contendo os estilos mesclados.
 */
function cls(
  condition: string,
  defaultStyle: CSSStyleClass,
  styleIfTrue: Record<string, CSSStyleClass>
): string;

/**
 * @overload Função que mescla estilos CSS somente caso condição for verdadeira.
 *
 * @param {boolean} condition Condição para a mesclagem dos estilos.
 * @param {CSSStyleClass} defaultStyle Estilo padrão que será aplicado independente da condição.
 * @param {CSSStyleClass} styleIfTrue Estilo que será mesclado caso a condição for verdadeira.
 * @returns {CSSStyleClass} Objeto contendo os estilos mesclados.
 */
function cls(
  condition: boolean,
  defaultStyle: CSSStyleClass,
  styleIfTrue: CSSStyleClass
): string;

/**
 * @overload Função que mescla estilos CSS baseado na condição.
 *
 * @param {boolean} condition Condição para a mesclagem dos estilos.
 * @param {CSSStyleClass} defaultStyle Estilo padrão que será aplicado independente da condição.
 * @param {CSSStyleClass} styleIfTrue Estilo que será mesclado caso a condição for verdadeira.
 * @param {CSSStyleClass} styleIfFalse Estilo que será mesclado caso a condição for falsa.
 * @returns {CSSStyleClass} Objeto contendo os estilos mesclados.
 */
function cls(
  condition: boolean,
  defaultStyle: CSSStyleClass,
  styleIfTrue: CSSStyleClass,
  styleIfFalse: CSSStyleClass
): string;

/**
 * Função que recebe uma condição e mescla os estilos CSS baseado nela.
 *
 * @param {boolean | string} condition Condições aplicáveis para mesclar os estilos.
 * @param {CSSStyleClass} defaultStyle Estilo padrão que será aplicado independente da condição
 * @param {Record<string, CSSStyleClass> | CSSStyleClass} styleIfTrue Estilos que são mesclados caso a condição corresponder ou for verdadeira.
 * @param {CSSStyleClass} styleIfFalse Estilo que será mesclado caso a condição for falsa.
 * @returns {CSSStyleClass} Objeto contendo os estilos mesclados.
 */
function cls(
  condition: boolean | string,
  defaultStyle: CSSStyleClass,
  styleIfTrue: CSSStyleClass | Record<string, CSSStyleClass>,
  styleIfFalse?: CSSStyleClass
): string {
  if (typeof condition === 'boolean' && typeof styleIfTrue) {
    if (condition) {
      return [defaultStyle, styleIfTrue].join(' ');
    } else {
      if (styleIfFalse) return [defaultStyle, styleIfFalse].join(' ');
      return defaultStyle;
    }
  } else if (typeof condition === 'string') {
    const selectedStyle = styleIfTrue[condition as keyof typeof styleIfTrue];

    if (!selectedStyle) return defaultStyle;

    return [defaultStyle, selectedStyle].join(' ');
  }

  return defaultStyle;
}

export default cls;
