import React, {
  ComponentPropsWithRef,
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import styles from './TextAreaInput.module.css';

type TextAreaInputRef = {
  reset: () => void;
};

type TextAreaInputProps = {
  label: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
  textArea?: Omit<ComponentPropsWithRef<'textarea'>, 'onChange' | 'maxLength'>;
};

const TextAreaInput = forwardRef<TextAreaInputRef, TextAreaInputProps>(
  ({ label, onChange, className, textArea }, ref) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
      reset: () => {
        if (textAreaRef.current) {
          textAreaRef.current.value = '';
        }
      },
    }));

    function onChangeWrapper(e: ChangeEvent<HTMLTextAreaElement>) {
      onChange && onChange(e);
    }

    return (
      <textarea
        className={`${styles.textarea} ${className}`}
        placeholder={label}
        onChange={onChangeWrapper}
        ref={textAreaRef}
        {...textArea}
      />
    );
  }
);

export default TextAreaInput;
