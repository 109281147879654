import React, { useEffect } from 'react';
import { OperationMSTypes } from '../../../types/definitions';
import { LayersControl, GeoJSON, Pane } from 'react-leaflet';
import toFeatureCollection from '../../../utils/geo/toFeatureCollection.util';
import { MultiPolygon } from 'geojson';
import withErrorBoundary from '../../boundaries/operations/withErrorBoundary.hook';
import ErrorFallbackNDVI from '../../boundaries/operations/ErrorFallbackNDVI';

type GlebeOverlayProps = {
  data: OperationMSTypes.ById['glebes'][number];
  setHasAnyData?: (value: boolean) => void;
};

function GlebeOverlay(props: GlebeOverlayProps) {
  const { data, setHasAnyData }: GlebeOverlayProps = props;

  if (!data) {
    throw new Error('No data');
  }

  const geometry = data?.geom;
  const details = data?.compliance?.details;

  const allGeoJsonDifference = !!details
    ? details
        .filter((detail) => detail?.geojson_difference)
        .flatMap((detail) => detail?.geojson_difference)
    : [];

  const differences = toFeatureCollection(allGeoJsonDifference);

  const allGeoJson = !!details
    ? details
        .filter((detail) => detail?.geojson)
        .flatMap((detail) => detail?.geojson)
    : [];

  const preservations = allGeoJson;
  const planting_area = data.geom_planting;

  const groupedPreservations = preservations.reduce((acc, curr) => {
    const name = curr.properties.name;

    if (!acc[name]) {
      acc[name] = [curr];
    } else {
      acc[name].push(curr);
    }

    return acc;
  }, {} as Record<string, GeoJSON.Feature<MultiPolygon, { id: string; name: string; description: string; type_area: string; year: number; color: string }>[]>);

  useEffect(() => {
    const hasAnyData =
      (groupedPreservations && Object.keys(groupedPreservations).length > 0) ||
      planting_area?.geometry?.coordinates?.length > 0 ||
      differences?.features?.length > 0;

    if (setHasAnyData) {
      setHasAnyData(hasAnyData);
    }
  }, [groupedPreservations, planting_area, differences, setHasAnyData]);

  return (
    <React.Fragment>
      {planting_area && planting_area?.geometry?.coordinates?.length > 0 && (
        <LayersControl.Overlay
          checked
          name="<span style='color: black'>Área agricultável</span>"
        >
          <Pane name={'Área agricultável'} style={{ zIndex: 1010 }}>
            <GeoJSON
              data={planting_area as any}
              style={{ color: 'black', fillOpacity: 0.1, weight: 2 }}
            />
          </Pane>
        </LayersControl.Overlay>
      )}
      {geometry && (
        <LayersControl.Overlay
          checked
          name="<span style='color: dodgerblue'>Gleba</span>"
        >
          <Pane name={'Gleba'} style={{ zIndex: 1009 }}>
            <GeoJSON
              data={geometry}
              style={{ color: 'dodgerblue', weight: 2 }}
            />
          </Pane>
        </LayersControl.Overlay>
      )}
      {groupedPreservations &&
        Object.keys(groupedPreservations).map((name, index) => {
          const featureCollection = toFeatureCollection(
            groupedPreservations[name]
          );
          const color =
            groupedPreservations[name][0].properties.color || 'black';

          return (
            <LayersControl.Overlay
              key={index}
              checked
              name={`<span style='color: ${color}'>${name}</span>`}
            >
              <Pane name={`${name}`} style={{ zIndex: 1000 }}>
                {featureCollection && (
                  <GeoJSON
                    data={featureCollection}
                    style={{ color: color, fillOpacity: 0.8, weight: 2 }}
                  />
                )}
              </Pane>
            </LayersControl.Overlay>
          );
        })}
      {differences && differences.features.length > 0 && (
        <LayersControl.Overlay
          checked
          name="<span style='color: red'>Área sobreposta</span>"
        >
          <Pane name={'Área sobreposta'} style={{ zIndex: 1008 }}>
            <GeoJSON
              data={differences}
              style={{ color: 'red', fillOpacity: 0.9, weight: 2 }}
            />
          </Pane>
        </LayersControl.Overlay>
      )}
    </React.Fragment>
  );
}

export default withErrorBoundary(GlebeOverlay, {
  fallback: ErrorFallbackNDVI,
});
