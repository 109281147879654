import React from 'react';
import { useEffect, useState } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

export default function useFilters<T extends Record<string, string>>(
  defaultInit?: T
) {
  const [searchParams, setSearchParams]: [URLSearchParams, SetURLSearchParams] =
    useSearchParams(defaultInit);
  const [filters, setFilters] = useState<Record<keyof T, string>>(
    Object.fromEntries(searchParams.entries()) as T
  );

  function clearFilters(): void {
    setSearchParams(defaultInit);
    setFilters({} as T);
  }

  function addFilter(filter: keyof T, value: string): void {
    let currentFilters = filters;

    if (!value) {
      delete currentFilters[filter];
    } else {
      currentFilters[filter] = value;
    }

    setFilters(currentFilters);
  }

  function applyFilters(): void {
    setSearchParams(filters);
  }

  useEffect(() => {
    if (searchParams.size > 0) {
      window.history.pushState({}, '', `?${searchParams}`);
    }
  }, [searchParams]);

  return {
    filters,
    searchParams,
    clearFilters,
    addFilter,
    applyFilters,
  };
}
