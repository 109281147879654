import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { User } from '../../../../types/definitions';
import { AuthSliceType } from '../auth.slice';

export const findUserAsync = createAsyncThunk(
  'auth/findUserAsync',
  async (token: string) => {
    const decodedToken = jwtDecode<JwtPayload & { user?: User }>(token);

    return {
      token: token,
      user: decodedToken.user,
    };
  }
);

export function findUserAction(
  state: AuthSliceType,
  action: PayloadAction<AuthSliceType['value']>
): void {
  state.value = action.payload;
  state.status = 'idle';
}
