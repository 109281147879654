import React from 'react';
import { Bar, Cell, Legend, Tooltip } from 'recharts';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  CustomCSSProperties,
  DashboardMSTypes,
} from '../../../types/definitions';
import { ReactNode } from 'react';
import styles from './HectaresChart.module.css';

type AlertChartProps = {
  data: DashboardMSTypes.Summary;
};

function HectaresChart(props: AlertChartProps) {
  const { data }: AlertChartProps = props;

  const dataWithNumericValues = data.map((datum) => {
    return { ...datum, sum_area: Number(datum.sum_area) };
  });

  function capitalizeAndTruncate(str: string, maxLength: number = 15): string {
    const capitalized = str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return capitalized.length > maxLength
      ? capitalized.slice(0, maxLength) + '...'
      : capitalized;
  }

  const formatNumber = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any;
  }

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (!active || !payload || !payload.length) return null;

    let { culture_name, sum_area } = payload[0].payload;

    let cultureName = capitalizeAndTruncate(culture_name);
    let sumArea = formatNumber(sum_area);

    return (
      <div className={styles.containerTooltip}>
        <p>
          Cultura:{' '}
          <b
            style={{
              color: usedColors.has(colors[culture_name])
                ? colors[culture_name]
                : '#343A40',
            }}
          >
            {cultureName}
          </b>
        </p>
        <p>
          Hectares: <b>{sumArea}</b>
        </p>
      </div>
    );
  };

  const colors: Record<string, string> = {
    Feijão: '#64DD17',
    Melancia: '#FFD54F',
    Milho: '#00B8D4',
    Sorgo: '#F44336',
    Tomate: '#7C4DFF',
    Arroz: '#009688',
    Soja: '#74A6FD',
  };

  const availableColors: string[] = [
    '#FF5722',
    '#FFC107',
    '#8BC34A',
    '#00BCD4',
    '#E91E63',
    '#9C27B0',
    '#3F51B5',
    '#CDDC39',
    '#FF9800',
  ];
  const usedColors: Set<string> = new Set(Object.values(colors));

  function getNextColor(): string {
    for (const color of availableColors) {
      if (!usedColors.has(color)) {
        return color;
      }
    }
    return '#343A40';
  }

  function selectColor(name: string): string {
    if (!colors[name]) {
      colors[name] = getNextColor();
      usedColors.add(colors[name]);
    }
    return colors[name];
  }

  function generateLegend(): ReactNode {
    const legend = data.map((datum, key) => {
      const color = selectColor(datum.culture_name);
      return (
        <li className={styles.item} key={key}>
          <span style={{ '--color': color } as CustomCSSProperties}></span>
          {datum.culture_name[0].toLocaleUpperCase() +
            datum.culture_name.slice(1).toLocaleLowerCase()}
        </li>
      );
    });

    return <ul className={styles.legend}>{legend}</ul>;
  }

  return (
    <ResponsiveContainer
      width={'100%'}
      minWidth={800}
      height={'100%'}
      minHeight={600}
    >
      <BarChart
        width={500}
        height={500}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        data={dataWithNumericValues}
      >
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis dataKey={'culture_name'} hide />
        <YAxis type="number" dataKey={'sum_area'} />
        <Legend content={generateLegend} />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey={'sum_area'} fill="#82ca9d">
          {data.map((datum, key) => {
            return (
              <Cell
                key={key}
                fill={selectColor(datum.culture_name)}
                radius={4}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default HectaresChart;
