import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from '../layout/app/AppLayout.layout';
import NewProfile from '../pages/settings/NewProfile/NewProfile';
import Operations from '../pages/operations/Operations.page';
import Polygons from '../pages/polygons/Polygons.page';
import Reports from '../pages/reports/Reports.page';
import Settings from '../pages/settings/Settings';
import Blockchain from '../pages/blockchain/Blockchain.page';
import Auth from '../pages/auth/Auth.page';
import Middleware from './Middleware';
import Operation from '../pages/operation/Operation.page';
import Dashboard from '../pages/dashboard/Dashboard.page';
import OperationsPrint from '../pages/operationsPrint/OperationsPrint.page';
import NewPassword from '../pages/login/pages/NewPassword/NewPassowrd.page';
import NotFound from '../pages/404NotFound/NotFound';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login/auth" element={<Auth />} />
        <Route path="/login/new-password" element={<NewPassword />} />
        <Route element={<Middleware />}>
          <Route path="/operations/:id/print" element={<OperationsPrint />} />
          <Route path="/polygons" element={<Polygons />} />
          <Route element={<AppLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/operations" element={<Operations />} />
            <Route path="/operations/:id" element={<Operation />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/new-profile" element={<NewProfile />} />
            <Route path="/blockchain" element={<Blockchain />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
