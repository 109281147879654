import React, { useState } from 'react';
import { ChangeEvent, ChangeEventHandler, ComponentPropsWithRef } from 'react';
import styles from './PasswordInput.module.css';

import eyeClose from '../../assets/eyeClose.svg';
import eyeOpen from '../../assets/eyeOpen.svg';

type PasswordInputProps = {
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
  className?: string;
  iconPassword?: boolean;
  value?: string;
};

function PasswordInput(props: PasswordInputProps) {
  const { label, inputProps, onChange, className, iconPassword, value } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  function onChangeWrapper(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    onChange && onChange(e);
  }

  function ShowPassword() {
    setShowPassword((prev) => !prev);
  }

  return (
    <div className={styles.field}>
      <input
        type={showPassword ? 'text' : 'password'}
        placeholder={label}
        className={`${styles.input} ${className}`}
        onChange={onChangeWrapper}
        value={value}
        {...inputProps}
      />
      {iconPassword && (
        <div className={styles.divIcon} onClick={ShowPassword}>
          <img
            src={showPassword ? eyeOpen : eyeClose}
            alt="eye"
            className={styles.icon}
          />
        </div>
      )}
    </div>
  );
}

export default PasswordInput;
