import React from 'react';
import { FallbackProps } from '../ErrorBoundary';

type GenericFallbackProps = FallbackProps & {
  message?: string;
};

function GenericFallback(props: GenericFallbackProps) {
  const { error, message } = props;

  return (
    <div>
      <p>{message}</p>
    </div>
  );
}

export default GenericFallback;
