import { ChangeEvent, ChangeEventHandler, ComponentPropsWithRef } from 'react';
import styles from './PasswordInput.module.css';

type PasswordInputProps = {
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
};

function PasswordInput(props: PasswordInputProps) {
  const { name, inputProps, onChange } = props;

  function onChangeWrapper(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    onChange && onChange(e);
  }

  return (
    <label htmlFor={name} className={styles.field}>
      {name}
      <input
        type="password"
        name={name}
        className={styles.input}
        onChange={onChangeWrapper}
        {...inputProps}
      />
    </label>
  );
}

export default PasswordInput;
