import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './InviteUserModal.module.css';
import iconClose from '../../../../../assets/close.svg';
import iconPaper from '../../../../../assets/paper.svg';
import Selector from '../../../../../components/Selector/Selector.component';
import Button from '../../../../../components/Button/Button.component';
import TextAreaInput from '../../../../../components/TextAreaInput/TextAreaInput.component';
import ProfileMS from '../../../../../services/ms/ProfileMS.service';
import { ProfileMSTypes } from '../../../../../types/definitions';
import AccountMS from '../../../../../services/ms/AccountMS.service';
import useStateSelector from '../../../../../store/hooks/useStateSelector.hook';
import { Modal } from '..';
import { validateEmail } from '@spot-spotsat/@utils/validation/Validation';

interface InviteUserProps {
  forwardRef?: React.RefObject<HTMLDialogElement>;
  onSuccess?: () => void;
}

function InviteUser({ forwardRef, onSuccess }: InviteUserProps): JSX.Element {
  const [data, setData] = useState<ProfileMSTypes.All['results']>([]);
  const [invite, setInvite] = useState<{
    profile_id: string;
    emails: string[];
  }>({ profile_id: '', emails: [] });
  const isLoading = useRef<boolean>(true);

  const { value } = useStateSelector('auth');

  const errorModalPassword = useRef<HTMLDialogElement>(null);
  const errorModalResponse = useRef<HTMLDialogElement>(null);

  const textAreaRef = useRef<{ reset: () => void }>(null);
  const selectorRef = useRef<{ reset: () => void }>(null);

  function handleSelector(e: MouseEvent<HTMLButtonElement>) {
    const profile_id = String(
      data.find((item) => item.description === e.currentTarget.textContent)?.id
    );

    setInvite((prev) => ({ ...prev, profile_id }));
  }

  function handleTextArea(e: ChangeEvent<HTMLTextAreaElement>) {
    const emails: string[] = e.target.value.replaceAll(' ', '').split(',');

    setInvite((prev) => ({ ...prev, emails }));
  }

  function closeModal() {
    setInvite({ profile_id: '', emails: [] });
    selectorRef.current?.reset();
    textAreaRef.current?.reset();
    forwardRef?.current?.close();
  }

  async function handleSendInvite() {
    const areAllEmailsValid = invite.emails.every((email) =>
      validateEmail(email)
    );

    if (!areAllEmailsValid || invite.emails.length === 0) {
      errorModalPassword.current?.showModal();
      return;
    }

    if (!invite.profile_id) {
      return;
    }

    const response = await AccountMS.Invite(value.user?.id!, invite);

    if (!response) {
      errorModalResponse.current?.showModal();
      return;
    }

    if (textAreaRef.current) {
      setInvite({ profile_id: '', emails: [] });
      selectorRef.current?.reset();
      textAreaRef.current?.reset();
    }

    onSuccess && onSuccess();
  }

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.allSettled([ProfileMS.All()]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData((response.value as ProfileMSTypes.All).results);
        }
      });

      isLoading.current = false;
    }

    fetchData();
  }, []);

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={closeModal}
          />
        </div>
        <div className={styles.divTitle}>
          <div className={styles.divIconTitle}>
            <img src={iconPaper} alt="paper" />
            <h4 className={styles.iconTitle}>Convidar usuário</h4>
          </div>
          <p className={styles.paragraph}>
            Informe abaixo os e-mails dos usuários que deseja convidar,
            separados por vírgula.
          </p>
          <Selector
            onClick={handleSelector}
            label="Perfil"
            ref={selectorRef}
            items={data
              .filter((item) => item.active === true)
              .map((item) => item.description)}
            className={styles.selector}
          />
          <TextAreaInput
            ref={textAreaRef}
            onChange={handleTextArea}
            label="E-mails dos usuários"
          />
          <p className={styles.paragraphSmall}>
            Adicione os e-mails separados por vírgula
          </p>
          <div className={styles.divButton}>
            <Button
              label="Cancelar"
              className={styles.buttonCancel}
              onClick={() => forwardRef?.current?.close()}
            />
            <Button
              onClick={handleSendInvite}
              label="Enviar"
              className={styles.buttonSend}
            />
          </div>
        </div>
        <div className={styles.divButton}></div>
      </div>
      <Modal.ActionResponse
        forwardRef={errorModalPassword}
        title="Por favor, insira um e-mail válido!"
      />
      <Modal.ActionResponse
        forwardRef={errorModalResponse}
        title="Ocorreu um erro, por favor, tente novamente!"
      />
    </dialog>
  );
}

export default InviteUser;
