import React from 'react';
import ErrorBoundary, { ErrorBoundaryProps } from '../ErrorBoundary';
import {
  ComponentType,
  ForwardRefExoticComponent,
  ForwardedRef,
  PropsWithoutRef,
  RefAttributes,
  createElement,
  forwardRef,
} from 'react';

export default function withErrorBoundary<Props extends object>(
  component: ComponentType<Props>,
  errorBoundaryProps: ErrorBoundaryProps
): ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<ComponentType<Props>>
> {
  const Wrapped = forwardRef(
    (props: Props, ref: ForwardedRef<ComponentType<Props>>) =>
      createElement(
        ErrorBoundary,
        errorBoundaryProps,
        createElement(component, { ...props, ref })
      )
  );

  return Wrapped;
}
