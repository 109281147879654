import React from 'react';
import cls from '../../utils/cls.util';
import styles from './StatusTag.module.css';

type StatusTagProps = {
  is_active: boolean;
};

function StatusTag(props: StatusTagProps): JSX.Element {
  const { is_active } = props;

  return (
    <p className={styles.tag}>
      <span
        className={cls(
          is_active,
          styles.icon,
          styles.vigente,
          styles.finalizado
        )}
      ></span>
      <span className={styles.text}>
        {is_active ? 'Vigente' : 'Finalizado'}
      </span>
    </p>
  );
}

export default StatusTag;
