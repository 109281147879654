import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { SlicesType } from '../../store';
import { User } from '../../../types/definitions';
import Cookies from 'js-cookie';
import clearAction from './actions/clear.action';
import { findUserAction, findUserAsync } from './actions/findUser.action';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import updateAuthUser from './actions/updateAuthUser.action';

export type AuthSliceType = SlicesType<{
  token?: string;
  user?: User;
}>;

const token = Cookies.get(String(process.env.REACT_APP_COOKIE_KEY_TOKEN));
const { user } = token
  ? jwtDecode<JwtPayload & { user?: User }>(token)
  : { user: undefined };

const initialState: AuthSliceType = {
  status: token ? 'idle' : 'not-initialized',
  value: {
    token: token ?? undefined,
    user: user ?? undefined,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear: clearAction,
    update: updateAuthUser,
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthSliceType>) => {
    builder
      .addCase(findUserAsync.fulfilled, findUserAction)
      .addCase(findUserAsync.pending, (state, _) => {
        state.status = 'loading';
      })
      .addCase(findUserAsync.rejected, (state, _) => {
        state.status = 'failed';
      });
  },
});

export default authSlice.reducer;
export const authSliceActions = authSlice.actions;
