import React, {
  ChangeEvent,
  ChangeEventHandler,
  ComponentPropsWithRef,
  InputHTMLAttributes,
} from 'react';
import styles from './TextFilter.module.css';

type TextFilterProps = {
  label: string;
  value?: string;
  type?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  input?: Omit<ComponentPropsWithRef<'input'>, 'onChange' | 'maxLength'>;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

function TextFilter(props: TextFilterProps) {
  const { label, value, onChange, input, type = 'text' } = props;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e);
  }

  return (
    <label htmlFor={label} className={styles.label}>
      <span>{label}</span>
      <input
        id={label}
        type={type}
        data-testid="inputText-element"
        className={styles.input}
        onChange={handleChange}
        value={value}
        {...input}
      />
    </label>
  );
}

export default TextFilter;
