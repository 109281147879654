import { useDispatch } from 'react-redux';
import { RootDispatch } from '../store';

/**
 * @description Hook para disparar uma ação para o redux store
 * @returns { ReturnType<typeof useDispatch<RootDispath>> } Função que dispara uma ação para o redux store
 */
export default function useActionsDispatcher(): ReturnType<
  typeof useDispatch<RootDispatch>
> {
  return useDispatch<RootDispatch>();
}
