import { LayersControl, GeoJSON, Pane } from 'react-leaflet';
import { OperationMSTypes } from '../../../types/definitions';
import toFeatureCollection from '../../../utils/geo/toFeatureCollection.util';
import React from 'react';
import { MultiPolygon } from 'geojson';
import withErrorBoundary from '@spot-spotsat/components/boundaries/operations/withErrorBoundary.hook';
import ErrorFallbackCompliance from '@spot-spotsat/components/boundaries/operations/ErrorFallbackCompliance';

type EnvironmentalPreservationAreaOverlayProps = {
  data: OperationMSTypes.ById['glebes'];
};

function ComplianceOverlay(props: EnvironmentalPreservationAreaOverlayProps) {
  const { data }: EnvironmentalPreservationAreaOverlayProps = props;

  if (!data) {
    throw new Error('No data');
  }

  const geometry: GeoJSON.FeatureCollection = toFeatureCollection(
    data.map((glebe) => glebe.geom)
  );

  const details = data?.flatMap((glebe) => glebe?.compliance?.details || []);

  const allComplianceGeoJsonProperty = data
    .filter((glebe) => glebe?.compliance?.property?.geojson)
    .map((glebe) => glebe?.compliance?.property?.geojson);

  const car: GeoJSON.FeatureCollection = toFeatureCollection(
    allComplianceGeoJsonProperty
  );

  const allGeoJsonDifference = details
    .filter((detail) => detail?.geojson_difference)
    .map((detail) => detail?.geojson_difference);

  const differences = toFeatureCollection(allGeoJsonDifference);
  const preservations = details.flatMap((detail) => detail.geojson);

  const groupedPreservations = preservations.reduce((acc, curr) => {
    const name = curr.properties.name;

    if (!acc[name]) {
      acc[name] = [curr];
    } else {
      acc[name].push(curr);
    }

    return acc;
  }, {} as Record<string, GeoJSON.Feature<MultiPolygon, { id: string; name: string; description: string; type_area: string; year: number; color: string }>[]>);

  const deforestation = groupedPreservations['Área de desflorestamento'] || [];

  const groupedByYearDeforestation = deforestation.reduce((acc, curr) => {
    const year = curr.properties.year;

    if (!acc[year]) {
      acc[year] = [curr];
    } else {
      acc[year].push(curr);
    }

    return acc;
  }, {} as Record<string, GeoJSON.Feature<MultiPolygon, { id: string; name: string; description: string; type_area: string; year: number; color: string }>[]>);

  return (
    <React.Fragment>
      <LayersControl.Overlay
        checked
        name={`<span style='color: dodgerblue'>Glebas</span>`}
      >
        <Pane name={'Glebas'} style={{ zIndex: 1009 }}>
          <GeoJSON data={geometry} style={{ color: 'dodgerblue', weight: 2 }} />
        </Pane>
      </LayersControl.Overlay>
      {car && car.features.length > 0 && (
        <LayersControl.Overlay
          checked
          name={`<span style='color: goldenrod'>CAR</span>`}
        >
          <Pane name={'CAR'} style={{ zIndex: 1010 }}>
            <GeoJSON
              data={car}
              style={{
                color: 'yellow',
                weight: 2,
                fillOpacity: 0,
                dashArray: '5, 5',
              }}
            />
          </Pane>
        </LayersControl.Overlay>
      )}
      {groupedPreservations &&
        Object.keys(groupedPreservations).map((name, index) => {
          if (name === 'Área de desflorestamento') return null;

          const featureCollection = toFeatureCollection(
            groupedPreservations[name]
          );
          const color =
            groupedPreservations[name][0].properties.color || 'black';

          return (
            <LayersControl.Overlay
              key={index}
              checked
              name={`<span style='color: ${color}'>${name}</span>`}
            >
              <Pane name={`${name}`} style={{ zIndex: 1000 }}>
                <GeoJSON
                  data={featureCollection}
                  style={{ color: color, fillOpacity: 0.8, weight: 2 }}
                />
              </Pane>
            </LayersControl.Overlay>
          );
        })}
      {groupedByYearDeforestation &&
        Object.keys(groupedByYearDeforestation).map((year, index) => {
          const featureCollection = toFeatureCollection(
            groupedByYearDeforestation[year]
          );
          const color =
            groupedByYearDeforestation[year][0].properties.color || 'black';

          return (
            <LayersControl.Overlay
              key={index}
              checked
              name={`<span style='color: ${color}'>Área de desflorestamento - ${year}</span>`}
            >
              <Pane
                name={`Área de desflorestamento - ${year}`}
                style={{ zIndex: 1000 }}
              >
                <GeoJSON
                  data={featureCollection}
                  style={{ color: color, fillOpacity: 0.8, weight: 2 }}
                />
              </Pane>
            </LayersControl.Overlay>
          );
        })}
      {differences && differences.features.length > 0 && (
        <LayersControl.Overlay
          checked
          name="<span style='color: red;'>Área sobreposta</span>"
        >
          <Pane name={'Área sobreposta'} style={{ zIndex: 1008 }}>
            <GeoJSON
              data={differences}
              style={{ color: 'red', fillOpacity: 0.9, weight: 2 }}
            />
          </Pane>
        </LayersControl.Overlay>
      )}
    </React.Fragment>
  );
}

export default withErrorBoundary(ComplianceOverlay, {
  fallback: ErrorFallbackCompliance,
});
