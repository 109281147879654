import React, {
  ComponentPropsWithoutRef,
  MouseEvent,
  MouseEventHandler,
} from 'react';
import styles from './ButtonFilter.module.css';
import Icon, { SVGs } from '../../Icon/Icon.component';

type ButtonVariants =
  | 'filled-primary'
  | 'outline-primary'
  | 'empty-primary'
  | 'filled-secondary'
  | 'outline-secondary'
  | 'empty-secondary'
  | 'filled-neutral'
  | 'outline-neutral'
  | 'empty-neutral';

type ButtonProps = {
  label?: string;
  variant?: ButtonVariants;
  iconName?: keyof typeof SVGs;
  styleIcon?: string;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  button?: Omit<ComponentPropsWithoutRef<'button'>, 'onClick'>;
};

function ButtonFilter(props: ButtonProps) {
  const {
    label,
    styleIcon,
    onClick,
    className,
    button,
    iconName = 'Filter',
    variant = 'primary',
    disabled,
  } = props;

  function onClickWrapper(e: MouseEvent<HTMLButtonElement>) {
    onClick && onClick(e);
  }

  return (
    <button
      className={`${styles.button} ${styles[variant]} ${className}`}
      onClick={(e) => onClickWrapper(e)}
      disabled={disabled}
      {...button}
    >
      {label}
      {
        <Icon
          className={`${styles.icon} ${styleIcon}`}
          name={iconName}
          data-testid="iconButton-element"
        />
      }
    </button>
  );
}

export default ButtonFilter;
