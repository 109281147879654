import {
  ComponentPropsWithRef,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './SelectorFilter.module.css';
import Icon from '../../Icon/Icon.component';
import React from 'react';

type ObjectBasedOptions<T extends { [key: string]: any }> = {
  itemsType: 'object';
  items: T[];
  itemValueKey: keyof T;
  itemLabelKey: keyof T;
};

type StringBasedOptions = {
  itemsType: 'string';
  items: string[];
};

type SelectorFilterProps<T extends { [key: string]: any }> = {
  label: string;
  loading?: boolean;
  itemSelected?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  button?: Omit<ComponentPropsWithRef<'button'>, 'onClick'>;
  value?: string;
  disabled?: boolean;
} & (ObjectBasedOptions<T> | StringBasedOptions);

function SelectorFilter<T extends { [key: string]: any }>(
  props: SelectorFilterProps<T>
) {
  const {
    label,
    button,
    loading,
    onClick,
    itemsType,
    items,
    itemSelected,
    value,
    disabled,
  } = props;

  const [selected, setSelected] = useState<string | undefined>(itemSelected);
  const [expanded, setExpanded] = useState<boolean>(false);

  const optionsRef = useRef<HTMLDivElement>(null);

  function onClickWrapper(
    e: MouseEvent<HTMLButtonElement>,
    item: string | undefined
  ) {
    setSelected(item);
    setExpanded(false);

    onClick && onClick(e);
  }

  function handleOutsideClick(e: globalThis.MouseEvent): void {
    if (optionsRef.current && !optionsRef.current.contains(e.target as Node)) {
      setExpanded(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', (e: globalThis.MouseEvent) =>
      handleOutsideClick(e)
    );
    return () => {
      document.removeEventListener('mousedown', (e: globalThis.MouseEvent) =>
        handleOutsideClick(e)
      );
    };
  }, []);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={styles.wrapper} role="listbox">
      <span>{label}</span>
      <button
        className={styles.input}
        onClick={() => setExpanded((prev) => !prev)}
        value={selected}
        {...button}
        disabled={disabled}
      >
        <p>{selected}</p>
        <Icon className={styles.icon} name="CaretDown" />
      </button>
      <div
        ref={optionsRef}
        className={`${styles.options} ${expanded && styles.optionsExpanded}`}
      >
        {loading && (
          <button
            role="option"
            aria-selected={false}
            className={styles.button}
            disabled
          >
            <p>Carregando...</p>
          </button>
        )}

        {!loading && !(selected?.valueOf() === 'Todos') && (
          <button
            role="option"
            aria-selected={false}
            onClick={(e) => onClickWrapper(e, undefined)}
            className={styles.button}
          >
            Remover seleção
          </button>
        )}

        {!loading &&
          itemsType === 'string' &&
          items.sort().map((item, key) => {
            if (!item) return <React.Fragment key={key}></React.Fragment>;

            return (
              <button
                role="option"
                aria-selected={selected === item}
                onClick={(e) => onClickWrapper(e, item)}
                className={styles.button}
                key={key}
                value={item}
              >
                {item}
              </button>
            );
          })}

        {!loading &&
          itemsType === 'object' &&
          items
            .sort((a, b) =>
              a[props.itemLabelKey].localeCompare(b[props.itemLabelKey])
            )
            .map((item, key) => {
              if (
                !item ||
                !item[props.itemValueKey] ||
                !item[props.itemLabelKey]
              )
                return <React.Fragment key={key}></React.Fragment>;

              return (
                <button
                  role="option"
                  aria-selected={selected === item[props.itemLabelKey]}
                  onClick={(e) => onClickWrapper(e, item[props.itemLabelKey])}
                  className={styles.button}
                  key={key}
                  value={item[props.itemValueKey]}
                >
                  {item[props.itemLabelKey]}
                </button>
              );
            })}
      </div>
    </div>
  );
}

export default SelectorFilter;
