export const ROUTES = {
  ROOT: '/',
  LOGIN: { ROOT: '/login' },
  DASHBOARD: { ROOT: '/dashboard' },
  REPORTS: { ROOT: '/reports' },
  PROFILE: { ROOT: '/profile' },
  OPERATIONS: { ROOT: '/operations' },
  BLOCKCHAIN: { ROOT: '/blockchain' },
  POLYGONS: { ROOT: '/blockchain' },
};
