import HTTP from '../HTTP';

export default abstract class AuthMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_ACCOUNT_MS!}/api/auth`;

  public static async ChangeActiveProfile(body: { profile_id: string }) {
    return await HTTP.POST<{ profile_id: string }, { token: string }>(
      `${this.ms_base_url}/active-profile`,
      body
    );
  }
}
