import React from 'react';
import cls from '../../utils/cls.util';
import styles from './Tag.module.css';

type TagSizes = 'small' | 'medium' | 'large';

type TagProps = {
  label: string;
  size?: TagSizes;
};

function Tag(props: TagProps): JSX.Element {
  const { label, size = 'small' } = props;

  const tagSize: Record<TagSizes, string> = {
    small: styles.small,
    medium: styles.medium,
    large: styles.large,
  };

  return <span className={cls(size, styles.tag, tagSize)}>{label}</span>;
}

export default Tag;
