import { useSelector } from 'react-redux';
import { RootState } from '../store';

/**
 * @description Hook para obter um slice do redux store
 * @param slice Nome do slice a ser retornado
 * @returns { RootState[slice] } Retorna o slice informado
 */
export default function useStateSelector<T extends keyof RootState>(
  slice: T
): RootState[T] {
  return useSelector((state: RootState) => state[slice]) as RootState[T];
}
