import { useEffect, useState } from 'react';

export default function useWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  const [windowDimensions, setWindowDimensions] = useState<{
    width: number;
    height: number;
  }>({ width, height });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ width, height });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width, height]);

  return windowDimensions;
}
