import React from 'react';
import Icon, { SVGs } from '../../../../components/Icon/Icon.component';
import styles from './ButtonTab.module.css';

type ButtonTabProps = {
  label: string;
  iconName: keyof typeof SVGs;
  active?: boolean;
  onClick?: () => void;
};

function ButtonTab({
  label,
  iconName,
  active = false,
  onClick,
}: ButtonTabProps): JSX.Element {
  return (
    <button
      className={active ? styles.buttonTabSelected : styles.buttonTab}
      onClick={onClick}
    >
      <Icon name={iconName} size={16} />
      {label}
    </button>
  );
}

export default ButtonTab;
