import React from 'react';
import spotViewLogo from '../../assets/spotViewLogo.svg';
import styles from './Loading.module.css';

function Loading(): JSX.Element {
  return (
    <div className={styles.loader}>
      <img
        src={spotViewLogo}
        style={{
          width: '120px',
        }}
        alt="SpotView"
      />
      <div className={styles.circle} />
    </div>
  );
}

export default Loading;
