import { AllGeoJSON, bbox } from '@turf/turf';
import { LatLngBoundsExpression } from 'leaflet';

/**
 * Calculates the bounding box (BBOX) of a GeoJSON object.
 *
 * @param {AllGeoJSON} geojson - The GeoJSON object for which to calculate the BBOX.
 * @return {LatLngBoundsExpression} - The bounding box (BBOX) of the GeoJSON object.
 */
export default function getBbox(geojson: AllGeoJSON): LatLngBoundsExpression {
  const bounds = bbox(geojson);

  let xMin = bounds[0];
  let yMin = bounds[1];
  let xMax = bounds[2];
  let yMax = bounds[3];

  return [
    [yMax, xMin],
    [yMin, xMax],
  ];
}
