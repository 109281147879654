export type WindowDimensions = {
  width: number;
  height: number;
};

/**
 * Retorna as dimensões atuais da janela
 *
 * @export
 * @return {*}  {WindowDimensions}
 */
export default function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}
