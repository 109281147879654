import { AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { authSliceActions } from '../slices/auth/auth.slice';
import { findUserAsync } from '../slices/auth/actions/findUser.action';

type AsyncActionsSatisfier = Record<string, AsyncThunk<any, any, any>>;
type ActionsSatisfier = Record<keyof RootState, Record<string, any>>;

const asynAuthSliceActions = {
  findUser: findUserAsync,
} satisfies AsyncActionsSatisfier;

const actions = {
  auth: { ...authSliceActions, ...asynAuthSliceActions },
} satisfies ActionsSatisfier;

export default function useActionsSelector<T extends keyof RootState>(
  slice: T
): (typeof actions)[T] {
  return actions[slice];
}
