import React from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconProps } from '../../../components/Icon/Icon.component';
import cls from '../../../utils/cls.util';
import styles from './Breadcrumb.module.css';

export type BreadcrumbsProps = {
  icon: IconProps['name'];
  breadcrumbs: { label: string; href: string; active?: boolean }[];
};

function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
  const { breadcrumbs, icon } = props;

  return (
    <nav aria-label="breadcrumb" className={styles.container}>
      <ol className={styles.breadcrumb}>
        <Icon name={icon} />
        {breadcrumbs.map((breadcrumb, key) => {
          const isActive = !!breadcrumb.active;

          return (
            <li
              key={key}
              aria-current={isActive}
              data-first={key === 0}
              className={cls(isActive, styles.item, styles.itemActive)}
            >
              <Link className={styles.link} to={breadcrumb.href}>
                {breadcrumb.label}
              </Link>
              {key < breadcrumbs.length - 1 ? (
                <span
                  data-testid="breadcrumb-divider"
                  className={styles.divider}
                >
                  {' '}
                  /
                </span>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
