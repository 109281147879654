import AlertsChart from './AlertsChart/AlertsChart.component';
import HectaresChart from './HectaresChart/HectaresChart.component';
import NDVIChart from './NDVIChart/NDVIChart.component';
import ChartWeather from './WeatherChart/ChartWeather.component';

export const Chart = {
  Alert: AlertsChart,
  Hectares: HectaresChart,
  NDVI: NDVIChart,
  Weather: ChartWeather,
};
