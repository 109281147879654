import React from 'react';
import iconClose from '../../../../../assets/close.svg';
import styles from './ActionConfirmationModal.module.css';
import Button from '../../../../../components/Button/Button.component';

type ActionConfirmationProps = {
  title: string;
  subtitle: string;
  forwardRef?: React.RefObject<HTMLDialogElement>;
  onConfirmed?: (value: any) => void;
};

export default function ActionConfirmation(props: ActionConfirmationProps) {
  const { forwardRef, onConfirmed, title, subtitle }: ActionConfirmationProps =
    props;

  function onCancel() {
    forwardRef?.current?.close();
  }
  function onSuccess() {
    onConfirmed && onConfirmed(forwardRef?.current?.dataset.value);
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={onCancel}
          />
        </div>
        <div className={styles.divIconTitle}>
          <h4 className={styles.iconTitle}>{title}</h4>
        </div>
        <p className={styles.paragraph}>{subtitle}</p>
        <div className={styles.divButton}>
          <Button
            label="Cancelar"
            className={styles.buttonCancel}
            onClick={() => forwardRef?.current?.close()}
          />
          <Button
            onClick={onSuccess}
            label="Confirmar"
            className={styles.buttonSend}
          />
        </div>
      </div>
    </dialog>
  );
}
