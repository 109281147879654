/**
 * Formats a number or string into a currency string representation in Brazilian Real (BRL).
 *
 * @param {number | string} value - The number or string to be formatted.
 * @return {string} The formatted currency string.
 */
export default function toCurrency(value: number | string): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
}
