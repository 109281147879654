import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/auth.slice';

export type SlicesType<T> = {
  value: T;
  status: 'not-initialized' | 'idle' | 'loading' | 'failed';
  metadata?: string;
};

export const store = configureStore({
  reducer: {
    auth: authSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
