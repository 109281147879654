import { GeoJSON } from 'react-leaflet';
import { FeatureCollection, Polygon } from 'geojson';

function AreaPolygon({
  data,
  getGeoFilter,
  setGeoFilter,
  setSelectedDataGlebe,
  setDataFilter,
  setTileLayerNDVI,
  setTileLayerRGB,
  tileLayerNDVI,
  tileLayerRGB,
  setHasFiltered,
  setSearchMessage,
}: any): JSX.Element {
  const geoFilter = getGeoFilter();

  return (
    <GeoJSON
      key="geo-json-areas-SJC"
      data={
        data?.glebes?.map(
          (glebe: any) => glebe?.geom
        ) as unknown as FeatureCollection<Polygon>
      }
      eventHandlers={{
        click: (e) => {
          setDataFilter({
            startDate: '',
            endDate: '',
          });

          setTileLayerNDVI(null);
          setTileLayerRGB(null);
          setHasFiltered(false);
          setSearchMessage('');
          const selectedFeature = e.propagatedFrom.feature;

          setGeoFilter((prevState: any) => {
            const isSameFeature =
              prevState?.properties?.name === selectedFeature?.properties?.name;

            if (!isSameFeature) {
              const selectedGlebe = data.glebes.find(
                (glebe: any) =>
                  glebe.geom.properties.name === selectedFeature.properties.name
              );

              if (selectedGlebe) {
                setSelectedDataGlebe({
                  dataChartWeather: selectedGlebe.weather?.daily || [],
                  dataChartNdvi: selectedGlebe.chart_ndvi || [],
                  dataNdviCopernicus: selectedGlebe.ndvi || [],
                  coordinatesGlebe: selectedGlebe.geom.geometry,
                });
              }
            }
            return isSameFeature ? null : selectedFeature;
          });
        },
      }}
      style={(feature) => {
        return {
          color:
            geoFilter?.properties?.name === feature?.properties?.name
              ? '#3388FF'
              : '#FFFFFF',
          fillColor:
            tileLayerNDVI || tileLayerRGB
              ? 'transparent'
              : feature?.properties?.color,
          weight: 3,
        };
      }}
    />
  );
}

export default AreaPolygon;
