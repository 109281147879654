import React, { useEffect, useState } from 'react';
import styles from './Panel.module.css';
import spotViewLogo from '../../../assets/spotViewLogo.svg';
import Icon from '../../../components/Icon/Icon.component';
import ButtonTab from '../components/ButtonTab/ButtonTab.component';
import { ChartTypes, Operation } from '../Polygons.page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OperationMSTypes } from '@spot-spotsat/types/definitions';
import OperationMS from '@spot-spotsat/services/ms/OperationMS.service';
import PolygonMS from '@spot-spotsat/services/ms/PolygonMS.service';
import toLocaleDate from '@spot-spotsat/utils/toLocaleDate.util';

interface PanelProps {
  selectedCharts: ChartTypes[];
  setSelectedCharts: (value: ChartTypes[]) => void;
  setShowChart: (chart: ChartTypes) => void;
  geoFilter: any;
  setGeoFilter: (value: any) => void;
  selectedGleba: Operation | null;
  setSelectedGleba: (value: Operation | null) => void;
  setIsLoading: (value: boolean) => void;

  selectedDataGlebe: any;
  selectedOperationPolygons: any;
  dataFilter: any;
  setDataFilter: (value: any) => void;
  setSelectedDataGlebe: (value: any) => void;
  setTileLayerNDVI: (value: any) => void;
  setTileLayerRGB: (value: any) => void;
  tileLayerNDVI: any;
  tileLayerRGB: any;
  setOperationPolygons: (value: any) => void;
  searchMessage: string;
  setSearchMessage: (value: any) => void;
  hasFiltered: boolean;
  setHasFiltered: (value: any) => void;
}

function Panel({
  selectedCharts,
  setSelectedCharts,
  setShowChart,
  geoFilter,
  setGeoFilter,
  selectedGleba,
  setSelectedGleba,
  setIsLoading,
  selectedDataGlebe,
  setSelectedDataGlebe,
  dataFilter,
  setDataFilter,
  setTileLayerNDVI,
  setTileLayerRGB,
  setOperationPolygons,
  tileLayerNDVI,
  tileLayerRGB,
  searchMessage,
  setSearchMessage,
  hasFiltered,
  setHasFiltered,
}: PanelProps): JSX.Element {
  const [isVisible, setIsVisible] = useState({
    polygons: false,
    weather: false,
    ndvi: false,
    rgb: false,
  });

  const navigate = useNavigate();

  useEffect(() => {}, [isVisible]);

  const [panelOpen, setPanelOpen] = useState(true);

  const [searchOperation, setSearchOperation] = useState('');

  const ClearFilterInputClose = () => {
    setSelectedGleba(null);
    setSearchOperation('');
    setGeoFilter(null);
    setDataFilter({ startDate: '', endDate: '' });
    setSelectedDataGlebe({
      dataChartWeather: [],
      dataChartNdvi: [],
      dataNdviCopernicus: [],
      coordinatesGlebe: [],
    });
    setTileLayerNDVI(null);
    setTileLayerRGB(null);
    setOperationPolygons(undefined);
    setHasFiltered(false);
    setSearchMessage('');
  };

  const [isError, setIsError] = useState(false);

  const GetOperationId = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    setSelectedGleba(null);
    setGeoFilter(null);
    setSelectedCharts([]);
    setDataFilter({ startDate: '', endDate: '' });
    setSelectedDataGlebe({
      dataChartWeather: [],
      dataChartNdvi: [],
      dataNdviCopernicus: [],
      coordinatesGlebe: [],
    });
    setHasFiltered(false);
    setSearchMessage('');

    const response = await OperationMS.ByNumberOperation(searchOperation);

    try {
      if (response === false) {
        setIsError(true);
        setIsLoading(false);
        return;
      }

      const updatedDataOperation = {
        operationId: response.id,
        centroid: [response.coordinates.lat, response.coordinates.lng],
        dataPolygon: response as OperationMSTypes.ById,
        dataChartWeather: response.glebes.map((glebe) => ({
          ...(glebe.weather?.daily || {}),
        })),
        dataChartNdvi: response.glebes.map((glebe) => ({
          ...glebe.chart_ndvi?.data,
        })),
        dataNdviCopernicus: response.glebes.map((glebe) => ({
          ...glebe.ndvi,
        })),
      };

      setSelectedGleba(updatedDataOperation as Operation);
      setSearchOperation(response.code.toString());
    } catch (error) {
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
  };

  const GetImageSatelliteNDVI = async (e, imageId, type, coordinates) => {
    e.preventDefault();

    setIsLoading(true);

    const response: any = await PolygonMS.AddImageSatellite({
      imageId: imageId,
      geometry: {
        type: type,
        coordinates: coordinates,
      },
    });

    if (response.value === false) {
      return;
    }

    setTileLayerRGB(null);
    setTileLayerNDVI(response.imageNDVIUrl);
    setIsLoading(false);
  };

  const GetImageSatelliteRGB = async (e, imageId, type, coordinates) => {
    e.preventDefault();

    setIsLoading(true);

    const response: any = await PolygonMS.AddImageSatellite({
      imageId: imageId,
      geometry: {
        type: type,
        coordinates: coordinates,
      },
    });

    if (response.value === false) {
      return;
    }

    setTileLayerNDVI(null);
    setTileLayerRGB(response.imageRGBUrl);
    setIsLoading(false);
  };

  const FormatedData = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const [tempMedia, setTempMedia] = useState(0);

  useEffect(() => {
    let tempMedia = 0;

    if (
      selectedDataGlebe?.dataChartWeather?.temperature_2m_mean &&
      selectedDataGlebe.dataChartWeather.temperature_2m_mean.length > 0
    ) {
      for (
        let i = 0;
        i < selectedDataGlebe.dataChartWeather.temperature_2m_mean.length;
        i++
      ) {
        tempMedia += selectedDataGlebe.dataChartWeather.temperature_2m_mean[i];
      }

      tempMedia /=
        selectedDataGlebe.dataChartWeather.temperature_2m_mean.length;
      setTempMedia(Number(tempMedia.toFixed(2)));
    }
  }, [selectedDataGlebe.dataChartWeather.temperature_2m_mean]);

  const [errorTitleInputDate, setErrorTitleInputDate] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !selectedDataGlebe.dataNdviCopernicus ||
      selectedDataGlebe.dataNdviCopernicus.length === 0
    ) {
      setErrorTitleInputDate(true);
    } else {
      setErrorTitleInputDate(false);
    }
  }, [selectedDataGlebe?.dataNdviCopernicus]);

  const filterImagesByDate = () => {
    const filteredNdvi =
      selectedDataGlebe?.dataNdviCopernicus?.images?.filter((item) => {
        const itemDate = new Date(item.date);
        const fromDate = dataFilter.startDate
          ? new Date(dataFilter.startDate)
          : null;
        const toDate = dataFilter.endDate ? new Date(dataFilter.endDate) : null;

        return (
          (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
        );
      }) || [];

    const filteredRgb =
      selectedDataGlebe?.dataNdviCopernicus?.images?.filter((item) => {
        const itemDate = new Date(item.date);
        const fromDate = dataFilter.startDate
          ? new Date(dataFilter.startDate)
          : null;
        const toDate = dataFilter.endDate ? new Date(dataFilter.endDate) : null;

        return (
          (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
        );
      }) || [];

    setHasFiltered(true);

    if (filteredNdvi.length === 0 && filteredRgb.length === 0) {
      setSearchMessage(
        'Não foi possível encontrar imagens NDVI e RGB nesse período de tempo.'
      );
    } else {
      setSearchMessage(
        'Pesquisa feita com sucesso, verifique as imagens no campo de NDVI e RGB.'
      );
    }
  };

  const clearFilter = () => {
    setDataFilter({
      ...dataFilter,
      startDate: '',
      endDate: '',
    });
    setSearchMessage('');
    setHasFiltered(false);
    setTileLayerRGB(null);
    setTileLayerNDVI(null);
  };

  useEffect(() => {
    if (dataFilter.startDate || dataFilter.endDate) {
      filterImagesByDate();
    }
  }, [dataFilter.startDate, dataFilter.endDate]);

  return (
    <>
      {' '}
      {panelOpen ? (
        <div className={styles.panelContainer}>
          <div className={styles.panelHeader}>
            <div
              className={styles.headerTitle}
              onClick={() => setPanelOpen(false)}
            >
              <span className={styles.buttonBack} onClick={() => navigate('/')}>
                <Icon name="ArrowLeft" />
                <img src={spotViewLogo} alt="" width={80} height={40} />
              </span>
              <span className={styles.buttonHandleVisibility}>
                <Icon name="CarretDoubleLeft" />
              </span>
            </div>
            <div className={styles.boxDataList}>
              <div className={styles.boxInputOperation}>
                <div className={styles.divInputOperation}>
                  <input
                    type="number"
                    className={styles.inputOperation}
                    placeholder="Pesquisar Operação"
                    value={searchOperation}
                    onChange={(e) => setSearchOperation(e.target.value)}
                  />
                  {selectedGleba && (
                    <div
                      className={styles.clearSelectedOperation}
                      onClick={ClearFilterInputClose}
                    >
                      X
                    </div>
                  )}
                </div>
                <button
                  onClick={(e) => GetOperationId(e)}
                  className={styles.buttonSearch}
                >
                  <Icon name="FilterPolygon" />
                </button>
              </div>
              {isError && (
                <span className={styles.errorInput}>
                  Insira uma operação válida!
                </span>
              )}
            </div>
            {geoFilter ? (
              <>
                <div className={styles.showGraphBlock}>
                  <div className={styles.legend}>Exibir Gráfico</div>
                  <ButtonTab
                    iconName={
                      selectedCharts.includes('ndvi') ? 'RadarHover' : 'Radar'
                    }
                    label="NDVI"
                    onClick={() => {
                      if (selectedCharts.includes('ndvi')) {
                        setSelectedCharts(
                          selectedCharts.filter((chart) => chart !== 'ndvi')
                        );
                      } else {
                        setSelectedCharts([...selectedCharts, 'ndvi']);
                        setShowChart('ndvi');
                      }
                    }}
                    active={selectedCharts.includes('ndvi')}
                  />
                  <ButtonTab
                    iconName={
                      selectedCharts.includes('weather')
                        ? 'ClimateHover'
                        : 'Climate'
                    }
                    label="Clima"
                    onClick={() => {
                      if (selectedCharts.includes('weather')) {
                        setSelectedCharts(
                          selectedCharts.filter((chart) => chart !== 'weather')
                        );
                      } else {
                        setSelectedCharts([...selectedCharts, 'weather']);
                        setShowChart('weather');
                      }
                    }}
                    active={selectedCharts.includes('weather')}
                  />
                </div>
                {errorTitleInputDate === true ? (
                  <p className={styles.paragraphTitleInputError}>
                    Não foram encontrados dados de NDVI e RGB nesse polígono.
                    Por favor, procure a operação novamente.
                  </p>
                ) : (
                  <p className={styles.paragraphTitleInput}>
                    Filtrar um período com imagens:
                  </p>
                )}
                <div className={styles.filterDate}>
                  <div className={styles.filterDateItem}>
                    <p className={styles.dateLegend}>De:</p>
                    <input
                      type="date"
                      disabled={errorTitleInputDate}
                      className={`${styles.inputDate} ${
                        errorTitleInputDate && styles.inputDateError
                      }`}
                      value={dataFilter.startDate}
                      min={
                        new Date(
                          new Date(
                            selectedGleba?.dataPolygon?.planting_start
                          ).setFullYear(
                            new Date(
                              selectedGleba?.dataPolygon?.planting_start
                            ).getFullYear() - 1
                          )
                        )
                          .toISOString()
                          .split('T')[0]
                      }
                      max={
                        new Date(
                          new Date(
                            selectedGleba?.dataPolygon?.crop_start
                          ).setDate(
                            new Date(
                              selectedGleba?.dataPolygon?.crop_start
                            ).getDate() + 30
                          )
                        )
                          .toISOString()
                          .split('T')[0]
                      }
                      onChange={(e) =>
                        setDataFilter({
                          ...dataFilter,
                          startDate: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className={styles.filterDateItem}>
                    <p className={styles.dateLegend}>Até:</p>
                    <input
                      type="date"
                      disabled={errorTitleInputDate}
                      className={`${styles.inputDate} ${
                        errorTitleInputDate && styles.inputDateError
                      }`}
                      value={dataFilter.endDate}
                      min={dataFilter.startDate || dataFilter.minDate}
                      max={
                        new Date(
                          new Date(
                            selectedGleba?.dataPolygon?.crop_start
                          ).setDate(
                            new Date(
                              selectedGleba?.dataPolygon?.crop_start
                            ).getDate() + 30
                          )
                        )
                          .toISOString()
                          .split('T')[0]
                      }
                      onChange={(e) =>
                        setDataFilter({
                          ...dataFilter,
                          endDate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                {hasFiltered && searchMessage && (
                  <>
                    <p
                      className={
                        searchMessage.includes('sucesso')
                          ? styles.successMessageFilter
                          : styles.errorMessageFilter
                      }
                    >
                      {searchMessage}
                    </p>

                    <button
                      onClick={clearFilter}
                      className={styles.clearFilterInputDate}
                    >
                      Limpar Filtro
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                {selectedGleba && (
                  <p className={styles.selectPolygonMessage}>
                    Selecione um polígono
                  </p>
                )}
              </>
            )}
          </div>
          {geoFilter && (
            <div className={styles.panelContent}>
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    polygons: !isVisible.polygons,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="MapNotFilled" size={16} />
                  <span>Dados do polígono</span>
                </div>
                <Icon
                  name={isVisible.polygons ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.polygons && (
                <>
                  <div className={styles.contentItemData}>
                    <span>Operação</span>
                    <span>{selectedGleba?.operationId}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Proprietário</span>
                    <span>{selectedGleba?.dataPolygon.owner}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Área</span>
                    <span>{selectedGleba?.dataPolygon.area} ha</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Cultura</span>
                    <span>{selectedGleba?.dataPolygon.culture_name}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Tipo de Solo</span>
                    <span>{selectedGleba?.dataPolygon.soil}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Zoneamento</span>
                    <span>{selectedGleba?.dataPolygon.zoning}</span>
                  </div>
                </>
              )}
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    weather: !isVisible.weather,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="Climate" size={16} />
                  <span>Clima</span>
                </div>
                <Icon
                  name={isVisible.weather ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.weather &&
                (!selectedDataGlebe.dataChartWeather ||
                  selectedDataGlebe.dataChartWeather.length === 0) && (
                  <p className={styles.selectPolygonMessageError}>
                    Dados indisponíveis no momento
                  </p>
                )}
              {isVisible.weather &&
                selectedDataGlebe.dataChartWeather &&
                selectedDataGlebe.dataChartWeather.temperature_2m_mean && (
                  <>
                    <div className={styles.contentItemData}>
                      <span>Temperatura Máxima</span>
                      <span>
                        {Math.max(
                          ...selectedDataGlebe.dataChartWeather
                            .temperature_2m_mean
                        )}
                      </span>
                    </div>
                    <div className={styles.contentItemData}>
                      <span>Temperatura Mínima</span>
                      <span>
                        {Math.min(
                          ...selectedDataGlebe.dataChartWeather
                            .temperature_2m_mean
                        )}
                      </span>
                    </div>
                    <div className={styles.contentItemData}>
                      <span>Temperatura Média</span>
                      <span>{tempMedia}</span>
                    </div>
                  </>
                )}
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    ndvi: !isVisible.ndvi,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="Radar" size={16} />
                  <span>NDVI</span>
                </div>
                <Icon
                  name={isVisible.ndvi ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.ndvi &&
                (!selectedDataGlebe.dataNdviCopernicus ||
                  selectedDataGlebe.dataNdviCopernicus.length === 0) && (
                  <p className={styles.selectPolygonMessageError}>
                    Dados indisponíveis no momento
                  </p>
                )}
              {isVisible.ndvi &&
                selectedDataGlebe.dataNdviCopernicus !== undefined && (
                  <>
                    {selectedDataGlebe.dataNdviCopernicus.images &&
                      selectedDataGlebe.dataNdviCopernicus.images
                        .filter((item) => {
                          const itemDate = new Date(item.date);
                          const fromDate = dataFilter.startDate
                            ? new Date(dataFilter.startDate)
                            : null;
                          const toDate = dataFilter.endDate
                            ? new Date(dataFilter.endDate)
                            : null;

                          return (
                            (!fromDate || itemDate >= fromDate) &&
                            (!toDate || itemDate <= toDate)
                          );
                        })
                        .map((item, index) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            key={index}
                            className={styles.contentItemNdvi}
                            onClick={(e) =>
                              GetImageSatelliteNDVI(
                                e,
                                item.id,
                                selectedDataGlebe.coordinatesGlebe.type,
                                selectedDataGlebe.coordinatesGlebe.coordinates
                              )
                            }
                          >
                            <span>{FormatedData(item.date)}</span>
                            <span>
                              <Icon name="Cloud" size={12} />
                              {item.cloud_cover.toFixed(2)}%
                            </span>
                          </a>
                        ))}
                  </>
                )}
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    rgb: !isVisible.rgb,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="Radar" size={16} />
                  <span>RGB</span>
                </div>
                <Icon
                  name={isVisible.rgb ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.rgb &&
                (!selectedDataGlebe.dataNdviCopernicus ||
                  selectedDataGlebe.dataNdviCopernicus.length === 0) && (
                  <p className={styles.selectPolygonMessageError}>
                    Dados indisponíveis no momento
                  </p>
                )}
              {isVisible.rgb &&
                selectedDataGlebe.dataNdviCopernicus !== undefined && (
                  <>
                    {selectedDataGlebe.dataNdviCopernicus.images &&
                      selectedDataGlebe.dataNdviCopernicus.images
                        .filter((item) => {
                          const itemDate = new Date(item.date);
                          const fromDate = dataFilter.startDate
                            ? new Date(dataFilter.startDate)
                            : null;
                          const toDate = dataFilter.endDate
                            ? new Date(dataFilter.endDate)
                            : null;

                          return (
                            (!fromDate || itemDate >= fromDate) &&
                            (!toDate || itemDate <= toDate)
                          );
                        })
                        .map((item, index) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            key={index}
                            className={styles.contentItemNdvi}
                            onClick={(e) =>
                              GetImageSatelliteRGB(
                                e,
                                item.id,
                                selectedDataGlebe.coordinatesGlebe.type,
                                selectedDataGlebe.coordinatesGlebe.coordinates
                              )
                            }
                          >
                            <span>{FormatedData(item.date)}</span>
                            <span>
                              <Icon name="Cloud" size={12} />
                              {item.cloud_cover.toFixed(2)}%
                            </span>
                          </a>
                        ))}
                  </>
                )}
            </div>
          )}
          {(tileLayerNDVI || tileLayerRGB) && (
            <button
              className={styles.clearFilterTileLayer}
              onClick={() => (setTileLayerRGB(null), setTileLayerNDVI(null))}
            >
              Limpar filtro
            </button>
          )}
        </div>
      ) : (
        <div
          className={styles.panelCollapsed}
          onClick={() => setPanelOpen(true)}
        >
          <span className={styles.buttonBack} onClick={() => navigate(-1)}>
            <Icon name="ArrowLeft" />
            <img src={spotViewLogo} alt="" width={80} height={40} />
          </span>
          <span className={styles.buttonHandleVisibility}>
            <Icon name="CarretDoubleRight" />
          </span>
        </div>
      )}
    </>
  );
}

export default Panel;
