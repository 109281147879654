export const titleChartEnum = {
  ndvi: 'NDVI',
  weather: 'Clima',
};

export const subtitleChartEnum = {
  ndvi: [
    {
      name: 'Índice Vegetativo',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#00940f"
          viewBox="0 0 256 256"
        >
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path>
        </svg>
      ),
    },
    {
      name: 'Data indicada de plantio no projeto',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#c2e2f9"
          viewBox="0 0 256 256"
        >
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path>
        </svg>
      ),
    },
    {
      name: 'Data indicada de colheita no projeto',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#f7e2bb"
          viewBox="0 0 256 256"
        >
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path>
        </svg>
      ),
    },
    {
      name: 'Plantio realizado',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#eb87cf"
          viewBox="0 0 256 256"
        >
          <path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Z"></path>
        </svg>
      ),
    },
    {
      name: 'Pico vegetativo',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#00ff00"
          viewBox="0 0 256 256"
        >
          <path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Z"></path>
        </svg>
      ),
    },
    {
      name: 'Início de senescência',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#dada00"
          viewBox="0 0 256 256"
        >
          <path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Z"></path>
        </svg>
      ),
    },
    {
      name: 'Colhido',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#ff0000"
          viewBox="0 0 256 256"
        >
          <path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Z"></path>
        </svg>
      ),
    },
  ],
  weather: [
    {
      name: 'Temperatura (°C)',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#ee1012"
          viewBox="0 0 256 256"
        >
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path>
        </svg>
      ),
    },
    {
      name: 'Precipitação (mm)',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2187e4"
          viewBox="0 0 256 256"
        >
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path>
        </svg>
      ),
    },
  ],
};
