import React from 'react';
import styles from './ErrorFallbackNDVI.module.css';

function ErrorFallbackNDVI() {
  return (
    <div className={styles.divError}>
      <p>Gleba não disponível no momento</p>
    </div>
  );
}

export default ErrorFallbackNDVI;
