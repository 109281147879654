import {
  ComponentPropsWithoutRef,
  MouseEvent,
  MouseEventHandler,
  useState,
} from 'react';
import styles from './Button.module.css';

type ButtonProps = {
  label: string;
  className?: string;
  buttonProps?: ComponentPropsWithoutRef<'button'>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

function Button(props: ButtonProps) {
  const { label, buttonProps, className, onClick } = props;

  const [loading, setLoading] = useState<boolean>(false);

  function onClickWrapper(e: MouseEvent<HTMLButtonElement>) {
    onClick && onClick(e);
  }

  return (
    <button
      className={`${styles.button} ${className}`}
      {...buttonProps}
      onClick={onClickWrapper}
    >
      {label}
    </button>
  );
}

export default Button;
