import HTTP from '../HTTP';

export default abstract class PolygonMS {
  private static readonly ms_base_url_polygons: string = `${process.env
    .REACT_APP_POLYGONS_MS!}/api/polygons`;

  private static readonly ms_base_url_sattelite: string = `${process.env
    .REACT_APP_POLYGONS_MS!}/api/satellite`;

  // Polygons
  public static async All() {
    return await HTTP.GET(`${this.ms_base_url_polygons}`);
  }

  public static async DownloadAll() {
    return await HTTP.GET(`${this.ms_base_url_polygons}/download`);
  }

  public static async Totals() {
    return await HTTP.GET(`${this.ms_base_url_polygons}/totals`);
  }

  public static async ById(id: string) {
    return await HTTP.GET(`${this.ms_base_url_polygons}/${id}`);
  }

  // Satellites

  public static async AllSatellite(id: number) {
    return await HTTP.GET(`${this.ms_base_url_sattelite}/${id}`);
  }

  public static async AddImageSatellite(body: {
    imageId: string;
    geometry: object;
  }) {
    return await HTTP.POST(`${this.ms_base_url_sattelite}/image`, body);
  }
}
