import React from 'react';
import { ChangeEvent, ChangeEventHandler, ComponentPropsWithRef } from 'react';
import styles from './TextInput.module.css';

type TextInputProps = {
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
  className?: string;
  value?: string;
};

function TextInput(props: TextInputProps) {
  const { label, inputProps, onChange, className, value } = props;

  function onChangeWrapper(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    onChange && onChange(e);
  }

  return (
    <div className={styles.field}>
      <input
        type="text"
        placeholder={label}
        className={`${styles.input} ${className}`}
        onChange={onChangeWrapper}
        value={value}
        {...inputProps}
      />
    </div>
  );
}

export default TextInput;
