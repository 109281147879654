/**
 * Formats a date string into a localized date string.
 *
 * @param {string} date - The date string to be formatted.
 * @param {boolean} [withTime] - Optional. Whether to include the time in the formatted date string. Defaults to false.
 * @return {string} The formatted date string.
 */
export default function toLocaleHour(date: string, withTime?: boolean): string {
  const adjustedDate = new Date(date);
  adjustedDate.setHours(
    adjustedDate.getHours() + adjustedDate.getTimezoneOffset() / 60
  );

  if (withTime) {
    return adjustedDate.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  return adjustedDate.toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
