import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import InputText from '../../../components/Filter/TextFilter/TextFilter.component';
import Page from '../../../layout/components/Page/Page.component';
import styles from './NewProfile.module.css';
import Button from '../../../components/Button/Button.component';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import CooperativesMS from '../../../services/ms/CooperativesMS.service';
import { CooperativesMSTypes } from '../../../types/definitions';
import ProfileMS from '../../../services/ms/ProfileMS.service';
import { Modal } from '../components/Modal';

function NewProfile() {
  const navigate: NavigateFunction = useNavigate();
  const location: Location<any> = useLocation();

  const [data, setData] = useState<{
    description: string;
    cooperatives_ids: string[];
  }>({
    description: location.state ? location.state.profile.description : '',
    cooperatives_ids: [],
  });
  const [cooperatives, setCooperatives] = useState<
    CooperativesMSTypes.All['results']
  >([]);
  const isLoading = useRef<boolean>(true);

  const isActiveInputRef = useRef<HTMLInputElement>(null);
  const actionResponseModal = useRef<HTMLDialogElement>(null);
  const actionUpdateModal = useRef<HTMLDialogElement>(null);
  const action: 'update' | 'new' =
    location.state && location.state.profile ? 'update' : 'new';

  function handleCheckbox(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.checked) {
      setData((prev) => ({
        description: prev.description,
        cooperatives_ids: [...prev.cooperatives_ids, e.target.value],
      }));
    } else {
      setData((prev) => ({
        description: prev.description,
        cooperatives_ids: prev.cooperatives_ids.filter(
          (item) => item !== e.target.value
        ),
      }));
    }
  }

  async function handleSave() {
    if (!data.description) {
      return;
    }

    if (action === 'update') {
      await ProfileMS.Update(location.state.profile.id!, {
        ...data,
        active: isActiveInputRef.current
          ? isActiveInputRef.current.checked
          : true,
      });
      if (!actionUpdateModal.current) return;
      actionUpdateModal.current.showModal();
      return;
    }

    await ProfileMS.AddProfile(data);
    if (!actionResponseModal.current) return;
    actionResponseModal.current.showModal();
  }

  useEffect(() => {
    async function fetchData() {
      const response = await CooperativesMS.All();

      if (!response) {
        return;
      }

      setCooperatives(response.results);

      isLoading.current = false;
    }

    async function fetchProfile(id: string) {
      const response = await ProfileMS.ById(id);

      if (!response) {
        return;
      }

      const correspondingCooperatives: string[] = response.cooperatives.map(
        (cooperative) => cooperative.id
      );
      setData((prev) => ({
        ...prev,
        cooperatives_ids: correspondingCooperatives,
      }));
    }

    fetchData();

    if (action === 'update') {
      fetchProfile(location.state.profile.id);
    }
  }, [action, location.state?.profile?.id]);

  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  useEffect(() => {
    const onlyLetters = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(data.description.trim());

    if (
      data.description.trim().length >= 5 &&
      onlyLetters &&
      data.cooperatives_ids.length > 0
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [data.cooperatives_ids, data.description]);

  return (
    <Page
      breadcrumbs={{
        icon: 'Gear',
        breadcrumbs: [
          { label: 'Configurações', href: '/settings', active: true },
        ],
      }}
    >
      <Button
        label="&larr; Voltar"
        className={styles.button}
        onClick={() => navigate('/settings?tab=2')}
      />
      <div className={styles.divTitle}>
        <h3 className={styles.title}>
          {action === 'update' ? 'Editar Perfil' : 'Novo Perfil'}
        </h3>
        {action === 'update' && (
          <label htmlFor="isActive">
            <input
              ref={isActiveInputRef}
              type="checkbox"
              name="isActive"
              id="isActive"
              defaultChecked={location.state.profile.active}
            />
            Ativo
          </label>
        )}
      </div>
      <InputText
        label="Nome"
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          const sanitizedValue = target.value
            .replace(/[^a-zA-ZÀ-ÿ\s]/g, '')
            .replace(/\s{2,}/g, ' ')
            .slice(0, 30);
          setData((prev) => ({ ...prev, description: sanitizedValue }));
        }}
        className={styles.inputText}
        value={data.description}
      />
      <p className={styles.subtitle}>Filiais/Cooperativas</p>
      <div className={styles.divList}>
        {cooperatives.map((item, index) => (
          <div key={index} className={styles.listaItem}>
            <input
              type="checkbox"
              onChange={handleCheckbox}
              checked={data.cooperatives_ids.includes(item.id)}
              value={item.id}
              className={styles.checkbox}
            />
            <div className={styles.textContainer}>
              <span className={styles.text}>
                {item.name}
                {' - '}
                {item.document_number.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5'
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.divButton}>
        <Button
          label="Enviar"
          onClick={handleSave}
          className={`${styles.buttonSend} ${
            disabledButton && styles.buttonSendDisabled
          }`}
          disabled={disabledButton}
        />
      </div>
      <Modal.ActionResponse
        forwardRef={actionResponseModal}
        title="Perfil criado com sucesso!"
        onClosed={() => navigate('/settings?tab=2')}
      />
      <Modal.ActionResponse
        forwardRef={actionUpdateModal}
        title="Perfil alterado com sucesso!"
        onClosed={() => navigate('/settings?tab=2')}
      />
    </Page>
  );
}

export default NewProfile;
