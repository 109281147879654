import React from 'react';
import styles from './Footer.module.css';

function Footer(): JSX.Element {
  return (
    <footer className={styles.footer}>
      <p>
        <b>SpotView</b> - SpotSat LTDA© {new Date().getFullYear()} - Todos os
        direitos reservados
      </p>
    </footer>
  );
}

export default Footer;
