import { ComponentPropsWithoutRef, ReactNode } from 'react';
import styles from './GroupFilter.module.css';
import cls from '../../../utils/cls.util';

type GroupFilterStates = 'grid' | 'flex';

type GroupFilterProps = {
  children?: ReactNode;
  type?: GroupFilterStates;
} & ComponentPropsWithoutRef<'fieldset'>;

function GroupFilter(props: GroupFilterProps) {
  const { children, type = 'grid', ...fieldset } = props;

  const groupStates: Record<GroupFilterStates, string> = {
    grid: styles.grid,
    flex: styles.flex,
  };

  return (
    <fieldset className={cls(type, styles.group, groupStates)} {...fieldset}>
      {children}
    </fieldset>
  );
}

export default GroupFilter;
