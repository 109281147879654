import React from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../types/constants';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

export default function Auth(): JSX.Element {
  const [searchParams]: [URLSearchParams, SetURLSearchParams] =
    useSearchParams();

  const token: string = searchParams.get('token') || '';
  let redirectUrl: string =
    searchParams.get('redirectUrl') || ROUTES.DASHBOARD.ROOT;

  useEffect(() => {
    function dispatchActions() {
      Cookies.set(process.env.REACT_APP_COOKIE_KEY_TOKEN!, token, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });

      window.location.href = redirectUrl;
    }

    dispatchActions();
  }, [redirectUrl, token]);

  return <></>;
}
