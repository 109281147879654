import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './AppLayout.module.css';
import Sidebar from '../components/Sidebar/Sidebar.component';
import cls from '../../utils/cls.util';
import getWindowDimensions from '../../utils/getWindowDimensions.util';

function AppLayout(): JSX.Element {
  const { width } = getWindowDimensions();
  const isTabletAndPhones = width < 768;

  return (
    <div
      className={cls(
        isTabletAndPhones,
        styles.layout,
        styles.small,
        styles.normal
      )}
    >
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default AppLayout;
