import React, { ReactElement } from 'react';
import styles from './RootFilter.module.css';

type RootFilterProps = {
  children?: ReactElement<HTMLFieldSetElement>[] | ReactElement;
};

function RootFilter(props: RootFilterProps) {
  const { children } = props;

  return (
    <form className={styles.root} onSubmit={(e) => e.preventDefault()}>
      {children}
    </form>
  );
}

export default RootFilter;
