import React from 'react';
import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentPropsWithRef,
  InputHTMLAttributes,
} from 'react';
import styles from './DateFilter.module.css';

type DateFilterProps = {
  label: string;
  value?: string;
  min?: string;
  max?: string;
  ref?: React.Ref<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  input?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

function DateFilter(props: DateFilterProps) {
  const today = new Date().toISOString().split('T')[0];
  const { label, value, min, max, ref, onChange, input } = props;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e);
  }

  return (
    <label htmlFor={label} className={styles.label}>
      <span>{label}</span>
      <input
        id={label}
        type="date"
        className={styles.input}
        onChange={handleChange}
        value={value}
        max={today || max}
        min={min}
        ref={ref}
        {...input}
      />
    </label>
  );
}

export default DateFilter;
