import { DashboardMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class DashboardMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_DASHBOARD_MS!}/api/dashboards`;

  public static async Domains() {
    return await HTTP.GET<DashboardMSTypes.Domains>(
      `${this.ms_base_url}/domains`
    );
  }

  public static async Summary(filters?: URLSearchParams) {
    return await HTTP.GET<DashboardMSTypes.Summary>(
      `${this.ms_base_url}/summary?${filters}`
    );
  }

  public static async Totals(filters?: URLSearchParams) {
    return await HTTP.GET<DashboardMSTypes.Totals>(
      `${this.ms_base_url}/totals?${filters}`
    );
  }

  public static async Details(filters?: URLSearchParams) {
    return await HTTP.GET<DashboardMSTypes.Details>(
      `${this.ms_base_url}/details?${filters}`
    );
  }

  public static async Alerts(filters?: URLSearchParams) {
    return await HTTP.GET<DashboardMSTypes.Alerts>(
      `${this.ms_base_url}/alerts?${filters}`
    );
  }
}
