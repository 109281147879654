import React, { useState } from 'react';
import styles from './Accordion.module.css';
import Icon from '../../../../components/Icon/Icon.component';

interface AccordionProps {
  title: string;
  children?: React.ReactNode;
  isChild?: boolean;
  onExpand?: () => void;
}

function Accordion(props: AccordionProps) {
  const { title, children, isChild, onExpand } = props;

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    if (onExpand && collapsed) {
      onExpand();
    }
  };

  return (
    <div className={styles.collapse} data-testid="accordion-wrapper">
      <div className={styles.collapseHeader} onClick={toggleCollapse}>
        <h3 className={isChild ? styles.childTitle : styles.collapseTitle}>
          {title}
        </h3>
        <span>
          {collapsed ? (
            <Icon name="CaretDown" data-testid="caret-down-icon" />
          ) : (
            <Icon name="CaretUp" data-testid="caret-up-icon" />
          )}
        </span>
      </div>
      {!collapsed && <div className={styles.collapseContent}>{children}</div>}
    </div>
  );
}

export default Accordion;
