import React, { useEffect, useState } from 'react';
import Page from '../../layout/components/Page/Page.component';
import styles from './Settings.module.css';
import Account from './Tabs/Account/Account';
import Profile from './Tabs/Profile/Profile';
import User from './Tabs/User/User';
import Counter from './Tabs/Counter/Counter';
import { useSearchParams } from 'react-router-dom';
import cls from '../../utils/cls.util';
import useStateSelector from '../../store/hooks/useStateSelector.hook';

export default function Settings() {
  const { value: auth } = useStateSelector('auth');

  const [searchParams, setSearchParams] = useSearchParams({ tab: String(1) });
  const [tab, setTab] = useState(Number(searchParams.get('tab')));
  const tabs: string[] = ['Conta', 'Perfis', 'Usuários', 'Contador'];

  function onTabSelect(index: number) {
    setTab(index + 1);
    setSearchParams({ tab: String(index + 1) });
  }

  useEffect(() => {
    if (!searchParams.get('tab')) return;

    setTab(Number(searchParams.get('tab')));
  }, [searchParams]);

  return (
    <Page
      breadcrumbs={{
        icon: 'Gear',
        breadcrumbs: [
          { label: 'Configurações', href: '/settings', active: true },
        ],
      }}
    >
      <div className={styles.nav}>
        {tabs.map((item, index) => {
          if (index > 0 && !auth.user?.is_admin) return null;

          return (
            <div
              key={index}
              className={cls(
                index + 1 === tab,
                styles.navItem,
                styles.selected
              )}
              onClick={() => onTabSelect(index)}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div>
        {tab === 1 && <Account />}
        {tab === 2 && auth.user?.is_admin && <Profile />}
        {tab === 3 && auth.user?.is_admin && <User />}
        {tab === 4 && auth.user?.is_admin && <Counter />}
      </div>
    </Page>
  );
}
