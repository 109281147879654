import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './ChartNDVIComponent.module.css';
import withErrorBoundary from '@spot-spotsat/components/boundaries/operations/withErrorBoundary.hook';
import GenericFallback from '@spot-spotsat/components/boundaries/operations/GenericErrorBoundary';

function ChartNDVI({ dataNDVI, dataAll }: any) {
  const ndvi = dataNDVI;

  const dots = ndvi.data
    .filter((data) => {
      const dotNames = [
        'Plantio realizado',
        'Pico vegetativo',
        'Inicio de senescência',
        'Colhido',
      ];
      return dotNames.some((dot) => data.name.includes(dot));
    })
    .map((dot) => {
      const currentDate = new Date(dot.x[0]);
      currentDate.setDate(currentDate.getDate() + 1);

      return {
        name: dot.name,
        x: currentDate.getTime(),
        y: dot.y[0],
      };
    });

  const planting_start = new Date(dataAll.planting_start);
  planting_start.setDate(planting_start.getDate() + 1);

  const planting_end = new Date(dataAll.planting_end);
  planting_end.setDate(planting_end.getDate() + 1);
  const original_planting_end = new Date(planting_end);
  planting_end.setDate(planting_end.getDate() + 1);

  if (planting_start.getTime() === planting_end.getTime()) {
    planting_end.setDate(planting_end.getDate() + 1);
  }

  const planting_period = [
    { x: planting_start.getTime(), y: 1 },
    { x: planting_end.getTime(), y: 1 },
  ];

  const harvest_start = new Date(dataAll.crop_start);
  harvest_start.setDate(harvest_start.getDate() + 1);

  const harvest_end = new Date(dataAll.crop_end);
  harvest_end.setDate(harvest_end.getDate() + 1);
  const original_harvest_end = new Date(harvest_end);
  harvest_end.setDate(harvest_end.getDate() + 1);

  if (harvest_start.getTime() === harvest_end.getTime()) {
    harvest_end.setDate(harvest_end.getDate() + 1);
  }

  const harvest_period = [
    { x: harvest_start.getTime(), y: 1 },
    { x: harvest_end.getTime(), y: 1 },
  ];

  const vegetative_stage = ndvi.data.find(
    (dot) => dot.name === 'Indice Vegetativo'
  );
  const mapped_stage = vegetative_stage!.x.map((x, index) => {
    const currentDate = new Date(x);
    currentDate.setDate(currentDate.getDate() + 1);

    return {
      x: currentDate.getTime(),
      y: vegetative_stage!.y[index],
    };
  });

  const all_data_merged = mapped_stage
    .concat(planting_period)
    .concat(harvest_period);

  function CustomDots(props: any) {
    const { payload, cx, cy } = props;
    let color = '#000000';

    const match = dots.find((dot) => dot.x === payload.x);

    if (match?.name.includes('Plantio realizado')) {
      color = '#E21AAA';
    } else if (match?.name.includes('Pico vegetativo')) {
      color = '#00FF00';
    } else if (match?.name.includes('Inicio de senescência')) {
      color = '#DADA00';
    } else if (match?.name.includes('Colhido')) {
      color = '#FF0000';
    }

    if (!!match) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r={5}
          fill="white"
          stroke={color}
          strokeWidth={4}
          fillOpacity={1}
        />
      );
    }

    return <></>;
  }

  function CustomXAxisLabel(props: any) {
    const { x, y, payload } = props;
    const date = new Date(payload.value).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
    });

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#EFEFEF">
          {date}
        </text>
      </g>
    );
  }

  function CustomTooltip(props: any) {
    const { payload } = props;
    let { x, y } = !!payload[0] ? payload[0].payload : { x: 0, y: 0 };

    const date = new Date(x).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    return (
      <div className={styles.tooltip}>
        <p className={styles.tooltipDate}>
          <b>Data:</b> {date}
        </p>
        <p className={styles.tooltipValue}>
          <b>Índice:</b> {y}
        </p>
      </div>
    );
  }

  return (
    <ResponsiveContainer
      width={'100%'}
      minWidth={800}
      height={'100%'}
      minHeight={100}
    >
      <ComposedChart data={all_data_merged} margin={{ right: 15 }}>
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid strokeDasharray="3 3" opacity={0.5} vertical={false} />
        <XAxis
          xAxisId={1}
          tick={<CustomXAxisLabel />}
          tickCount={20}
          dataKey={'x'}
          type="number"
          domain={[mapped_stage[0].x, mapped_stage[mapped_stage.length - 1].x]}
        />
        <XAxis
          xAxisId={2}
          type="number"
          dataKey={'x'}
          domain={[mapped_stage[0].x, mapped_stage[mapped_stage.length - 1].x]}
          hide
        />
        <YAxis dataKey={'y'} domain={[0, 1]} tick={{ fill: '#EFEFEF' }} />
        <Area
          xAxisId={1}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00940FFF"
          fill="#00940F22"
          data={mapped_stage}
        />
        <Line
          xAxisId={1}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00000000"
          dot={<CustomDots />}
          data={mapped_stage}
        />
        <Area
          xAxisId={2}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00000000"
          fill="#B3E0FFaa"
          data={planting_period}
        />
        {mapped_stage[mapped_stage.length - 1].x > harvest_period[1].x && (
          <Area
            xAxisId={2}
            type={'monotone'}
            dataKey={'y'}
            stroke="#00000000"
            fill="#FFD078aa"
            data={harvest_period}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default withErrorBoundary(ChartNDVI, {
  fallback: GenericFallback,
});
