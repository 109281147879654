import { AllGeoJSON, centroid } from '@turf/turf';
import { LatLngExpression } from 'leaflet';

/**
 * Calculates the centroid of a GeoJSON object.
 *
 * @param {AllGeoJSON} geojson - The GeoJSON object to calculate the centroid for.
 * @return {LatLngExpression} The centroid of the GeoJSON object as a LatLngExpression.
 */
export default function getCentroid(geojson: AllGeoJSON): LatLngExpression {
  return [
    centroid(geojson).geometry.coordinates[1],
    centroid(geojson).geometry.coordinates[0],
  ] as LatLngExpression;
}
