import React from 'react';
import { PropsWithChildren } from 'react';
import Breadcrumbs, {
  BreadcrumbsProps,
} from '../Breadcrumb/Breadcrumb.component';
import styles from './Page.module.css';
import Footer from '../Footer/Footer.component';

type PageProps = PropsWithChildren<{
  breadcrumbs: BreadcrumbsProps;
}>;

function Page(props: PageProps) {
  const { children, breadcrumbs } = props;

  return (
    <main className={styles.container}>
      <Breadcrumbs {...breadcrumbs} />
      <hr />
      {children}
      <Footer />
    </main>
  );
}

export default Page;
