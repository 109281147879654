import React from 'react';
import styles from './NotFound.module.css';
import Button from '../../components/Button/Button.component';
import useWindowDimensions from '../login/hooks/useWindowDimensions.hook';
import cls from '../../utils/cls.util';
import { useNavigate } from 'react-router-dom';

function NotFound(): JSX.Element {
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const navigate = useNavigate();

  function onNavigate() {
    navigate('/');
  }

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section></section>
      <section className={styles.content}>
        <h2 className={styles.title}>404</h2>
        <p className={styles.description}>Página não encontrada</p>
        <Button
          label="Voltar para home"
          className={styles.button}
          onClick={onNavigate}
        />
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
        </section>
      )}
    </main>
  );
}

export default NotFound;
