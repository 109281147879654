import React from 'react';
import { useState, useRef } from 'react';
import Icon from '../../components/Icon/Icon.component';
import Page from '../../layout/components/Page/Page.component';
import styles from './Blockchain.module.css';
import Button from '../../components/Button/Button.component';

function Blockchain(): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState<string | null>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setMessage('');
    const file = e.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setMessage('Solte o arquivo aqui');
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMessage('');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const saveFileOnBlockchain = () => {
    // Implementar a lógica de salvar o arquivo na blockchain
  };

  return (
    <Page
      breadcrumbs={{
        icon: 'Chart',
        breadcrumbs: [{ label: 'Blockchain', href: '/', active: true }],
      }}
    >
      <header className={styles.headers}>
        <h3>Verificador de Integridade</h3>

        <p>
          Se você registrou ou recebeu um arquivo registrado no Notalhub,
          selecione-o para verificar se é uma cópia autêntica.
        </p>
      </header>

      <div className={styles.divUpload}>
        <div
          className={styles.uploadBox}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={handleBoxClick}
        >
          {!file && !message ? (
            <>
              <div className={styles.dragText}>
                <Icon name="ClosedHand" />
                <p>Arraste para cá ou faça o upload do arquivo abaixo:</p>
              </div>
              <Button label="Selecionar Arquivo" className={styles.button} />
            </>
          ) : (
            <>
              {message ? (
                <p>{message}</p>
              ) : (
                file && (
                  <div className={styles.fileProperties}>
                    <div className={styles.fileInfo}>
                      <p className={styles.selectedFileTilte}>
                        <Icon name="File" /> Arquivo selecionado
                      </p>

                      <span>Nome do arquivo: {file?.name}</span>
                      <span> Tamanho: {file?.size / 1000} KB </span>
                      <span>Tipo: {file?.type} </span>
                    </div>
                    <Button label="Alterar Arquivo" className={styles.button} />
                    <Button
                      label="Salvar"
                      className={styles.saveButton}
                      onClick={saveFileOnBlockchain}
                    />
                  </div>
                )
              )}
            </>
          )}
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className={styles.fileInput}
          ref={fileInputRef}
          data-testid="file-input"
        />
      </div>
    </Page>
  );
}

export default Blockchain;
