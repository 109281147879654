import { ReportMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class ReportMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_REPORT_MS!}/api/reports`;

  public static async RegisterBlockchains(filters?: URLSearchParams) {
    return await HTTP.GET<ReportMSTypes.RegisterBlockchains>(
      `${this.ms_base_url}/register-blockchain?${filters}`
    );
  }
}
