import React, {
  MouseEvent,
  MouseEventHandler,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styles from './Selector.module.css';
import Icon from './../Icon/Icon.component';

type SelectorProps = {
  items: string[];
  label: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type SelectorRef = {
  reset: () => void;
};

const Selector = forwardRef<SelectorRef, SelectorProps>(
  ({ items, onClick, className, label }, ref) => {
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const [expanded, setExpanded] = useState<boolean>(false);

    function onClickWrapper(e: MouseEvent<HTMLButtonElement>, item: string) {
      setSelected(item);
      setExpanded(false);
      onClick && onClick(e);
    }

    useImperativeHandle(ref, () => ({
      reset: () => setSelected(undefined),
    }));

    return (
      <div className={`${styles.wrapper} ${className}`} role="listbox">
        <button
          className={styles.input}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <p>{!selected ? label : selected}</p>
          <Icon className={`${styles.icon}`} name="CaretDown" />
        </button>
        <div
          className={`${styles.options} ${expanded && styles.optionsExpanded}`}
        >
          {items.map((item, key) => (
            <button
              role="option"
              aria-selected="false"
              onClick={(e) => onClickWrapper(e, item)}
              className={styles.button}
              key={key}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    );
  }
);

export default Selector;
