import { ProfileMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class ProfileMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_ACCOUNT_MS!}/api/profiles`;

  public static async All() {
    return await HTTP.GET<ProfileMSTypes.All>(`${this.ms_base_url}`);
  }

  public static async ById(id: string) {
    return await HTTP.GET<ProfileMSTypes.ById>(`${this.ms_base_url}/${id}`);
  }

  public static async AddProfile(body: {
    description: string;
    cooperatives_ids: string[];
  }) {
    return await HTTP.POST(`${this.ms_base_url}`, body);
  }

  public static async Update(
    id: string,
    body: { description: string; cooperatives_ids: string[]; active: boolean }
  ) {
    return await HTTP.PATCH(`${this.ms_base_url}/${id}`, body);
  }

  public static async Delete(id: string) {
    return await HTTP.DELETE(`${this.ms_base_url}/${id}`);
  }
}
