import React from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import styles from './SidebarLinks.module.css';
import cls from '../../../utils/cls.util';
import Icon, { IconProps } from '../../../components/Icon/Icon.component';

type SidebarLink = {
  name: string;
  to: string;
  icon: IconProps['name'];
};

type SidebarLinksProps = {
  isCollapsed: boolean;
};

function SidebarLinks(props: SidebarLinksProps): JSX.Element {
  const { isCollapsed }: SidebarLinksProps = props;
  const { pathname }: Location = useLocation();

  const links: SidebarLink[] = [
    { name: 'Dashboard', to: '/', icon: 'House' },
    { name: 'Operações', to: '/operations', icon: 'Chart' },
    { name: 'Relatórios', to: '/reports', icon: 'File' },
    { name: 'Blockchain', to: '/blockchain', icon: 'Attachment' },
    { name: 'Polígonos', to: '/polygons', icon: 'Polygon' },
  ];

  return (
    <nav className={styles.nav}>
      {links.map((link, key) => {
        const { to, icon, name }: SidebarLink = link;
        const isCurrentPage: boolean = pathname === to;

        return (
          <Link
            key={key}
            to={to}
            data-collapsed={isCollapsed}
            target={name === 'Polígonos' ? '_blank' : '_self'}
            className={cls(isCurrentPage, styles.link, styles.active)}
          >
            <Icon className={styles.icon} name={icon} size={18} />
            <p className={styles.content}>{name}</p>
          </Link>
        );
      })}
    </nav>
  );
}

export default SidebarLinks;
