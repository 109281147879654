import ActionConfirmationModal from './ActionConfirmation/ActionConfirmationModal';
import ActionResponseModal from './ActionResponse/ActionResponseModal';
import EditUserModal from './EditUser/EditUserModal';
import InviteUserModal from './InviteUser/InviteUserModal';

export const Modal = {
  InviteUser: InviteUserModal,
  EditUser: EditUserModal,
  ActionConfirmation: ActionConfirmationModal,
  ActionResponse: ActionResponseModal,
};
