/**
 * Converts an array of GeoJSON features into a GeoJSON feature collection.
 *
 * @param {GeoJSON.Feature[]} features - An array of GeoJSON features to be converted into a feature collection.
 * @return {GeoJSON.FeatureCollection} - The resulting GeoJSON feature collection.
 */
export default function toFeatureCollection(
  features: GeoJSON.Feature[]
): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: features,
  };
}
