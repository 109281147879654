import React from 'react';
import { Component, ComponentType, PropsWithChildren } from 'react';

export type FallbackProps = {
  error: Error;
};

type ErrorBoundaryState =
  | {
      hasError: true;
      error: Error;
    }
  | {
      hasError: false;
      error: null;
    };

export type ErrorBoundaryProps = PropsWithChildren<{
  fallback: ComponentType<FallbackProps & { message?: string }>;
  fallbackMessage?: string;
}>;

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // console.error("Erro capturado pelo ErrorBoundary:", error, errorInfo);
  }

  render() {
    const { fallback: Fallback, children, fallbackMessage } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return <Fallback error={error} message={fallbackMessage} />;
    }

    return children;
  }
}
