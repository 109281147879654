import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '../../../../components/Button/Button.component';
import Icon from '../../../../components/Icon/Icon.component';
import styles from './Profile.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ProfileMS from '../../../../services/ms/ProfileMS.service';
import { ProfileMSTypes } from '../../../../types/definitions';
import { Modal } from '../../components/Modal';

function Profile() {
  const [data, setData] = useState<ProfileMSTypes.All['results']>([]);
  const [reload, setReload] = useState<boolean>(false);
  const navigate = useNavigate();

  const actionConfirmationModal = useRef<HTMLDialogElement>(null);
  const actionResponseModal = useRef<HTMLDialogElement>(null);
  const errorModalResponse = useRef<HTMLDialogElement>(null);
  const isLoading = useRef<boolean>(true);

  const columns = [
    {
      field: 'active',
      headerName: 'Status',
      flex: 0,
      minWidth: 120,
      renderCell: (params: any) => (
        <div className={!!params.value ? styles.ativado : styles.desativado}>
          {!!params.value ? 'Ativado' : 'Desativado'}
        </div>
      ),
    },
    { field: 'description', headerName: 'Perfil', flex: 1, minWidth: 200 },
    {
      field: 'id',
      headerName: 'Ações',
      flex: 0,
      renderCell: (params: any) => (
        <div className={styles.actions}>
          <button
            onClick={() =>
              navigate('/settings/new-profile', {
                state: { profile: params.row },
              })
            }
          >
            <Icon name="Pen" />
          </button>
          <button onClick={() => onRemoveCall(params.value)}>
            <Icon name="Trash" />
          </button>
        </div>
      ),
    },
  ];

  function onRemoveCall(id: string) {
    if (!actionConfirmationModal.current) return;

    actionConfirmationModal.current.dataset.value = id;
    actionConfirmationModal.current.showModal();
  }

  async function onRemoveProfile(value: any) {
    if (!actionConfirmationModal.current) return;
    actionConfirmationModal.current.close();

    const response = await ProfileMS.Delete(value);

    if (!response) {
      errorModalResponse.current?.showModal();
      return;
    }

    if (!actionResponseModal.current) return;

    actionResponseModal.current.showModal();

    setReload(!reload);
  }

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.allSettled([ProfileMS.All()]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData((response.value as ProfileMSTypes.All).results);
        }
      });

      isLoading.current = false;
    }

    fetchData();
  }, [reload]);

  return (
    <div className={styles.container}>
      <div>
        <Button
          label="Novo Perfil"
          className={styles.button}
          onClick={() => navigate('/settings/new-profile')}
        />
      </div>
      <div className={styles.divTable}>
        <DataGrid
          loading={isLoading.current}
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          pageSizeOptions={[25]}
          columns={columns}
          rows={data}
          sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
          disableColumnResize
          disableColumnMenu
          autoHeight
        />
      </div>
      <Modal.ActionConfirmation
        forwardRef={actionConfirmationModal}
        title="Tem certeza que deseja excluir este perfil?"
        subtitle="Esta ação não pode ser desfeita."
        onConfirmed={onRemoveProfile}
      />
      <Modal.ActionResponse
        forwardRef={actionResponseModal}
        title="Perfil excluído com sucesso!"
      />
      <Modal.ActionResponse
        forwardRef={errorModalResponse}
        title="Ocorreu um erro ao excluir o perfil, por favor, tente novamente!"
      />
    </div>
  );
}

export default Profile;
