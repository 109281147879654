import ButtonFilter from './ButtonFilter/ButtonFilter.component';
import DateFilter from './DateFilter/DateFilter.component';
import SelectorFilter from './SelectorFilter/SelectorFilter.component';
import TextFilter from './TextFilter/TextFilter.component';
import GroupFilter from '../Filter/GroupFilter/GroupFilter.component';
import RootFilter from '../Filter/RootFilter/RootFilter.component';

export const Filter = {
  Date: DateFilter,
  Text: TextFilter,
  Selector: SelectorFilter,
  Button: ButtonFilter,
  Group: GroupFilter,
  Root: RootFilter,
};
